import qrcode from 'qrcode';
import React, { useEffect, useState } from 'react';

const GenerateQRCode = () => {
    const [qrCode, setQrCode] = useState('');

    useEffect(() => {
        const generateQR = async () => {
            const urlCv = 'https://www.jukumaritravels.com/';
            try {
                const QR = await qrcode.toDataURL(urlCv);
                setQrCode(QR); // Guardar el código QR en el estado
            } catch (error) {
                console.error('Error generando el código QR:', error);
            }
        };

        generateQR();
    }, []);

    return (
        <div className="text-center mt-5">
            {qrCode ? (
                <img src={qrCode} alt="Código QR" style={{ width: 200 }} />
            ) : (
                <p>Generando código QR...</p>
            )}
        </div>
    );
};

export default GenerateQRCode;
