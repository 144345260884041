import 'animate.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Loading } from '../dashoboard/view/Loading';
import Footer from '../page/Footer';
import Navibar from '../page/Navibar';


const PaquetesPorDestino = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [destino, setDestino] = useState({});
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(`Fetching data for ID: ${id}`); // Log para identificar el ID que se está consultando

        const response = await axios.get(`/api/destinos/listid/${id}`);
        console.log('Response data:', response.data);

        if (response.data.ok) {
          setDestino(response.data.destino);
          console.log('Destino:', response.data.destino); // Log para ver los datos del destino
          setPaquetes(response.data.paquetesPorDestino);
          console.log('Paquetes:', response.data.paquetesPorDestino); // Log para ver los datos de los paquetes

          if (response.data.paquetesPorDestino.length === 0) {
            setError('No se encontraron paquetes para este destino');
          }
        } else {
          setError('No se encontraron paquetes para este destino');
          console.warn('No se encontraron paquetes para este destino'); // Log de advertencia si no hay paquetes
        }
      } catch (error) {
        setError('Error al obtener los paquetes del destino');
        console.error('Error fetching data:', error.message); // Log de error detallado
      } finally {
        setLoading(false);
        console.log('Finished fetching data'); // Log para indicar que se ha terminado de hacer la solicitud
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'info',
        title: 'Este destino no tiene paquetes activos',
        showConfirmButton: false,
        timer: 1000,
        didClose: () => {
          // Usar navigate para redirigir
          navigate('/destinos');
        }
      });
    }
  }, [error, navigate]); 

  useEffect(() => {
    window.scrollTo(0, 0); // Hacer scroll hacia arriba al cargar la página
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return null;
  }

  // Función para capitalizar la primera letra y poner las demás en minúscula
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div>
      <Navibar />

      <div>
        {destino ? (
          <div>
            {/* Imagen para pantallas grandes */}
            {destino.img && (
              <img
                className="animate__animated animate__fadeIn d-none d-md-block" // Oculto en pantallas menores a "md"
                src={destino.img}
                alt={destino.nombreDestino}
                style={{
                  width: '100%',
                  height: '480px',
                  objectFit: 'cover',
                }}
              />
            )}

            {/* Imagen para pantallas pequeñas */}
            {destino.img && (
              <img
                className="animate__animated animate__fadeIn d-block d-md-none" // Oculto en pantallas "md" y mayores
                src={destino.img}
                alt={destino.nombreDestino}
                style={{
                  width: '100%',
                  height: '250px', // Ajustar la altura para pantallas pequeñas
                  objectFit: 'cover',
                }}
              />
            )}
          </div>
        ) : (
          <p>No se encontró el destino.</p>
        )}
        
      </div>

      {/* -------------------------------- */}

      <div className="container mt-5">

        <div className="jumbotron mb-5">
          <div className="container">
            <h1 className=" animate__animated animate__backInLeft">Paquetes turisticos de {capitalizeFirstLetter(destino.nombreDestino)}</h1>
            <p className="lead mt-5 ">{destino.descripcionDestino}</p>
            <hr className="my-4" />
          </div>
        </div>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {paquetes.map((paquete) => (
            <div key={paquete._id} className="col mb-4">
              <div className="card h-100 shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bold text-uppercase" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>{paquete.nombrePaqueteTuristico}</h5>
                  <h4 className="card-subtitle mb-3 text-colormenu mt-4">Servicios incluidos</h4>
                  <hr className="line" />
                  <ul className="list-group list-group-flush">
                    {paquete.detallesPaqueteTuristicoSinPrecio.map((detalle, index) => (
                      <li key={index} className="list-group">{detalle.nombredetallesPaqueteTuristico}</li>
                    ))}
                  </ul>
                  <h4 className="card-subtitle mb-3 text-colormenu mt-4">Lo que no incluye</h4>
                  <hr className="line" />
                  <ul className="list-group list-group-flush">
                    {paquete.detallesPaqueteTuristicoConPrecio.map((detalle, index) => (
                      <li key={index} className="list-group">{detalle.nombredetallesPaqueteTuristico}</li>
                    ))}
                  </ul>
                </div>
                <div className="card-footer p-3 rounded d-flex justify-content-between align-items-center">
                  <Link
                    to={`https://wa.me/573043635185?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20el%20paquete%20"${paquete.nombrePaqueteTuristico}".%20¿Podrías%20ayudarme?%20Gracias.%20(Mensaje%20automático%20desde%20la%20página%20Web)`}
                    className="colormenu text-white fw-bold py-3 px-4 rounded d-flex justify-content-between align-items-center w-100"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Contacta a un asesor</span>
                    <i className="fa-solid fa-headset fa-2x"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        {paquetes.length === 0 && (
          <div className="text-center mt-4">
            <p>No hay paquetes disponibles para este destino.</p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PaquetesPorDestino;
