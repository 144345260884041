// src/components/AlertadeViaje.jsx

import React from 'react';

const AlertadeViaje = ({ fechaSalida, fechaLlegada }) => {
    const currentDate = new Date(); // Fecha actual
    const fechaSalidaDate = new Date(fechaSalida); // Fecha de salida
    const fechaLlegadaDate = fechaLlegada ? new Date(fechaLlegada) : null; // Fecha de llegada

    // Calcular la diferencia en días entre la fecha actual y la fecha de salida
    const diffInDays = Math.ceil((fechaSalidaDate - currentDate) / (1000 * 60 * 60 * 24));
    
    // Inicializar el color del ícono en blanco por defecto
    let iconColor = '#FFFFFF';

    // Condiciones para determinar el color del ícono
    if (fechaLlegadaDate && currentDate > fechaLlegadaDate) {
        iconColor = '#A93226'; // Después de la fecha de llegada, color rojo
    } else if (currentDate >= fechaSalidaDate && (!fechaLlegadaDate || currentDate <= fechaLlegadaDate)) {
        iconColor = '#28B463'; // Durante el viaje, desde fecha de salida hasta fecha de llegada, color verde
    } else if (diffInDays >= 1 && diffInDays <= 5) {
        iconColor = '#F1C40F'; // Entre 1 y 5 días antes de la fecha de salida, color amarillo
    }

    // Retornar el ícono con el color correspondiente
    return (
        <i className="fa-solid fa-circle fa-xl" style={{ color: iconColor }}></i>
    );
};

export default AlertadeViaje;
