import axios from 'axios';
import Pagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Breadcrumbs from '../components/Breadcrumbs ';
import { useUsuarios } from '../context/UsuariosContext';



const Usuarios = () => {
  const { usuarios } = useUsuarios();
  const [user, setUsuarios] = useState([])
  const [documentoUsuario, setDocumentoUsuario] = useState('')
  const [nombreUsuario, setNombreUsuario] = useState('')
  const [aliasUsuario, setAliasUsuario] = useState('')
  const [emailUsuario, setEmailUsuario] = useState('')
  const [passwordUsuario, setPasswordUsuario] = useState('')
  const [rolUsuario, setRolUsuario] = useState('')
  const [telefonoUsuario, setTelefonoUsuario] = useState('')
  const [whatsappUsuario, setWhatsappUsuario] = useState('')
  const [direccionUsuario, setDireccionUsuario] = useState('')

  const [edit, setEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Paginacion
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState('')


  const [paisUsuario, setPaisUsuario] = useState('');
  const [estadoUsuario, setEstadoUsuario] = useState('');
  const [ciudadUsuario, setCiudadUsuario] = useState('');
  //----------------------------------------------------------------
  // Estos estados y cambios de estado son relacionados con los Select o listas desplegables
  const [paises, setPaises] = useState([]);
  const [estados, setEstados] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState("");
  const [estadoSeleccionado, setEstadoSeleccionado] = useState("");
  const [ciudadSeleccionada, setCiudadSeleccionada] = useState("");

  useEffect(() => {
    getData(page);
    obtenerPaises();
    //fetchusers();
  }, [page]);

  const cleanData = () => {
    setUsuarios('')
    setDocumentoUsuario('')
    setNombreUsuario('')
    setAliasUsuario('')
    setEmailUsuario('')
    setPasswordUsuario('')
    setRolUsuario('')

    setTelefonoUsuario('')
    setWhatsappUsuario('')
    setEmailUsuario('')
    setDireccionUsuario('')

    setPaisUsuario('');
    setEstadoUsuario('');
    setCiudadUsuario('');

    setPaisSeleccionado('');
    setEstadoSeleccionado('');
    setCiudadSeleccionada('');

    setEdit(false);
  }

  const getData = async (pageCurrent) => {
    const { data } = await axios.get(`/api/usuarios/list/?page=${pageCurrent}`);
    setUsuarios(data.user.docs);
    //setFilteredusuarios(data.usuarios.docs);
    setPage(data.user.page);
    setTotalPages(data.user.totalPages);
  };

  const onchangePage = (page) => {
    getData(page);
  }

  const roles = ['Asesor', 'Admin', 'SuperAdmin'];

  const saveUsuario = async () => {
    try {
      const newUsuario = {
        documentoUsuario,
        nombreUsuario,
        aliasUsuario,
        emailUsuario,
        passwordUsuario,
        rolUsuario,
        telefonoUsuario,
        whatsappUsuario,
        direccionUsuario,

        paisUsuario,
        estadoUsuario,
        ciudadUsuario,

      }
      await axios.post('/api/usuarios/add/', newUsuario);
      cleanData();
      getData();
      closeModal();

      // SweetAlert2 para mostrar éxito
      Swal.fire({
        icon: 'success',
        title: 'El Usuario a sido registrado con exito',
        showConfirmButton: false,
        timer: 1500
      });

      setTimeout(() => {
        getData(); // Recargar los datos después de un breve tiempo
      }, 1000); // Esperar 1 segundo antes de recargar


    } catch (error) {
      if (!error.response.data.ok) {
        return alert(error.response.data.message)
      }
      console.log('error en saveUsuario', error.message);

    }
  }
  const updateUsuario = async () => {
    try {
      const id = localStorage.getItem('id');
      const newUsuario = {
        documentoUsuario,
        nombreUsuario,
        aliasUsuario,
        emailUsuario,
        passwordUsuario,
        rolUsuario,
        telefonoUsuario,
        whatsappUsuario,
        direccionUsuario,

        paisUsuario,
        estadoUsuario,
        ciudadUsuario,

      };

      const { data } = await axios.put('/api/usuarios/' + id, newUsuario);

      setPaisSeleccionado(paisUsuario);
      setEstadoSeleccionado(estadoUsuario);
      setCiudadSeleccionada(ciudadUsuario);

      cleanData();
      getData();
      closeModal();
      // SweetAlert2 para mostrar éxito
      Swal.fire({
        icon: 'success',
        title: data.message,
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      if (!error.response.data.ok) {
        return alert(error.response.data.message)
      }
      console.log('error en saveUsuario', error.message);
    }
  }
  const editData = (item) => {

    setEdit(true);
    setDocumentoUsuario(item.documentoUsuario)
    setNombreUsuario(item.nombreUsuario)
    setAliasUsuario(item.aliasUsuario)
    setEmailUsuario(item.emailUsuario)
    setTelefonoUsuario(item.telefonoUsuario)
    setWhatsappUsuario(item.whatsappUsuario);
    setRolUsuario(item.rolUsuario);
    setPasswordUsuario(item.passwordUsuario);
    setEmailUsuario(item.emailUsuario)
    setDireccionUsuario(item.direccionUsuario)

    setPaisUsuario(item.paisUsuario || '');
    setEstadoUsuario(item.estadoUsuario || '');
    setCiudadUsuario(item.ciudadUsuario || '');

    // Configurar los valores de selección de país, estado y ciudad
    setPaisSeleccionado(item.paisUsuario || '');
    handlePaisChange(item.paisUsuario)
    setEstadoSeleccionado(item.estadoUsuario || '');
    handleEstadoChange(item.estadoUsuario)
    setCiudadSeleccionada(item.ciudadUsuario || '');
    handleCiudadChange(item.ciudadUsuario)


    localStorage.setItem('id', item._id);
    setIsModalOpen(true);

  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteUsuario = async (id) => {
    try {
      Swal.fire({
        title: 'Esta seguro?',
        text: "No podras revertir el proceso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await axios.delete('/api/usuarios/' + id);
          getData();
          Swal.fire({
            icon: 'success',
            title: data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    } catch (error) {
      if (!error.response.data.ok) {
        return alert(error.response.data.message)
      }
      console.log('error en deleteUsuario', error.message);
    }
  }

  const actions = async (e) => {
    e.preventDefault();
    edit ? updateUsuario() : saveUsuario();
  };



  const obtenerPaises = async () => {
    try {
      const response = await axios.get('/api/paises/listall');
      console.log("Lista de paises:", response);
      setPaises(response.data.paises);
    } catch (error) {
      console.error("Error al obtener la lista de países:", error);
    }
  };

  const handlePaisChange = (paisId) => {
    setPaisSeleccionado(paisId);
    setPaisUsuario(paisId); // Almacenar en paisUsuario
    obtenerEstadosPorPais(paisId);
  };

  const handleEstadoChange = (estadoId) => {
    setEstadoSeleccionado(estadoId);
    setEstadoUsuario(estadoId); // Almacenar en estadoUsuario
    obtenerCiudadesPorEstado(estadoId);
  };

  const handleCiudadChange = (ciudadId) => {
    setCiudadSeleccionada(ciudadId);
    setCiudadUsuario(ciudadId); // Almacenar en ciudadUsuario
  };

  const obtenerEstadosPorPais = async (paisId) => {
    try {
      const response = await axios.get(`/api/paises/listid/${paisId}`);
      setEstados(response.data.estados || []);
      setEstadoSeleccionado('');
      setCiudadSeleccionada('');
    } catch (error) {
      console.error("Error al obtener los estados por país:", error);
      setEstados([]);
    }
  };

  const obtenerCiudadesPorEstado = async (estadoId) => {
    try {
      const response = await axios.get(`/api/estados/listid/${estadoId}`);
      setCiudades(response.data.ciudades || []);
      setCiudadSeleccionada('');
    } catch (error) {
      console.error("Error al obtener las ciudades por estado", error);
      setCiudades([]);
    }
  };






  return (
    <div>
      <div className=" container" style={{ textAlign: 'left' }}>
        <Breadcrumbs />
      </div>
      {/* Inicio del formulario*/}
      <div className='container-md mt-5'>
        <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!isModalOpen} style={{ display: isModalOpen ? 'block' : 'none' }}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#008cba" }}>
                <h5 className="modal-title text-white" id="exampleModalLabel">Ingreso de Usuarios</h5>
                <button type="button" className="btn-close bg-white" onClick={() => {
                  cleanData(); // Limpia los campos del formulario
                  getData(); // Carga los datos actualizados
                  closeModal();
                }} />
              </div>
              <div className="modal-body">
                <form id='UsuarioForm' onSubmit={actions}>
                  <div className="row g-3">

                    <div className="col-md-3">
                      <label htmlFor="documentoUsuario" className="form-label"><strong>Número de Documento</strong> </label><span className="text-danger red-asterisk">*</span>
                      <input type="number" className="form-control" id="documentoUsuario"
                        value={documentoUsuario}
                        onChange={(e) => setDocumentoUsuario(e.target.value.toUpperCase())}
                        required
                        disabled={(usuarios.rolUsuario === "Admin") && edit}

                      />
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="nombreUsuario" className="form-label"><strong>Nombre Completo</strong></label><span className="text-danger red-asterisk">*</span>
                      <input type="text" className="form-control" id="nombreUsuario"
                        value={nombreUsuario}
                        onChange={(e) => setNombreUsuario(e.target.value.toUpperCase())}
                        required
                        disabled={(usuarios.rolUsuario === "Admin") && edit} />
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="aliasUsuario" className="form-label"><strong>Alias</strong></label><span className="text-danger red-asterisk">*</span>
                      <input type="text" className="form-control" id="aliasUsuario"
                        value={aliasUsuario}
                        onChange={(e) => setAliasUsuario(e.target.value.toUpperCase())}
                        required
                        disabled={(usuarios.rolUsuario === "Admin") && edit} />
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="emailUsuario" className="form-label"><strong>Correo Electrónico</strong></label><span className="text-danger red-asterisk">*</span>
                      <input type="email" className="form-control" id="emailUsuario"
                        value={emailUsuario}
                        onChange={(e) => setEmailUsuario(e.target.value)}
                        required />
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="telefonoUsuario" className="form-label"><strong>Teléfono</strong></label><span className="text-danger red-asterisk">*</span>
                      <input type="number" className="form-control" id="telefonoUsuario"
                        value={telefonoUsuario}
                        onChange={(e) => setTelefonoUsuario(e.target.value)}
                        required />
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="whatsappUsuario" className="form-label"><strong>Whatsapp</strong></label>
                      <input type="number" className="form-control" id="whatsappUsuario"
                        value={whatsappUsuario}
                        onChange={(e) => setWhatsappUsuario(e.target.value)}
                        required />
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="rolUsuario" className="form-label"><strong>Rol Usuario</strong></label><span className="text-danger red-asterisk">*</span>
                      <select
                        className="form-select"
                        id="rolUsuario"
                        name="rolUsuario"
                        value={rolUsuario}
                        onChange={(e) => setRolUsuario(e.target.value)}
                        disabled={(usuarios.rolUsuario === "Admin") && edit}
                      >
                        <option value="">Selecciona un rol</option>
                        {roles.map((rol) => (
                          <option key={rol} value={rol}>{rol}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="passwordUsuario" className="form-label"><strong>Password Usuario</strong></label><span className="text-danger red-asterisk">*</span>
                      <input type="password" className="form-control" id="passwordUsuario"
                        value={passwordUsuario}
                        onChange={(e) => setPasswordUsuario(e.target.value)}
                        required />
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="paisUsuario" className="form-label"><strong>País</strong></label><span className="text-danger red-asterisk">*</span>
                      <select
                        className="form-select"
                        value={edit ? paisUsuario : paisSeleccionado}
                        onChange={(e) => handlePaisChange(e.target.value)}
                        disabled={(usuarios.rolUsuario === "Admin") && edit}
                      >
                        <option value="">Selecciona un país</option>
                        {paises.map((pais) => (
                          <option key={pais.idPais} value={pais.idPais}>{pais.nombrePais}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="estadoUsuario" className="form-label"><strong>Estado</strong></label><span className="text-danger red-asterisk">*</span>
                      <select
                        className="form-select"
                        value={edit ? estadoUsuario : estadoSeleccionado}
                        onChange={(e) => handleEstadoChange(e.target.value)}
                        disabled={(usuarios.rolUsuario === "Admin") && edit}
                      >
                        <option value="">Selecciona un estado</option>
                        {estados.map((estado) => (
                          <option key={estado.idEstado} value={estado.idEstado}>{estado.nombreEstado}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="ciudadUsuario" className="form-label"><strong>Ciudad</strong></label><span className="text-danger red-asterisk">*</span>
                      <select
                        className="form-select"
                        value={ edit ? ciudadUsuario : ciudadSeleccionada}
                        onChange={(e) => handleCiudadChange(e.target.value)}
                        disabled={(usuarios.rolUsuario === "Admin") && edit}
                      >
                        <option value="">Selecciona una ciudad</option>
                        {ciudades.map((ciudad) => (
                          <option key={ciudad.idCiudad} value={ciudad.idCiudad}>{ciudad.nombreCiudad}</option>
                        ))}
                      </select>

                    </div>

                    <div className="col-md-3">
                      <label htmlFor="direccionUsuario" className="form-label"><strong>Dirección</strong></label><span className="text-danger red-asterisk">*</span>
                      <input type="text" className="form-control" id="direccionUsuario"
                        value={direccionUsuario}
                        onChange={(e) => setDireccionUsuario(e.target.value.toUpperCase())}
                        required />
                    </div>


                    {/* ... (código existente) ... */}
                    <div className="modal-footer border-5">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          getData(); // Carga los datos actualizados
                          cleanData(); // Limpia los campos del formulario
                          closeModal();

                          document.getElementById("UsuarioForm").click(); // Cierra el modal
                        }}
                        data-bs-dismiss="modal"
                      >
                        Cerrar
                      </button>
                      <button type="submit" className="btn btn-primary">Guardar Registro</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* Fin del formulario*/}


      {/* Inicio de la tabla de Clientes*/}
      <div className='container container-flex card Larger shadow mt-3'>
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="dropdown no-arrow align-items-center">
            <button className="btn btn-link btn-sm dropdown-toggle" aria-expanded="false" data-bs-toggle="dropdown" type="button">
              <i className="fas fa-ellipsis-v text-gray-400"></i>
            </button>
            <div className="dropdown-menu shadow dropdown-menu-end animated--fade-in">
              <label className="text-center dropdown-header">Exportar:</label>
              <Link className="dropdown-item" href="#">
                <i className="fa-solid fa-file-pdf me-2"></i>Pdf
              </Link>
              <Link className="dropdown-item" href="#">
                <i className="fa-solid fa-file-excel me-2"></i> Excel
              </Link>
            </div>
          </div>
          <div>
            <h6 className="text-primary fw-bold m-0 mt-1 text-start">Lista de Usuarios</h6>
          </div>

          <div className='ms-3'>
            <button type="button" className="btn btn-primary rounded-circle aling-end" style={{ backgroundColor: "#008cba" }} onClick={() => {
              setIsModalOpen(true); // Abre la modal al hacer clic
            }} title="Haga clic para agregar un nuevo cliente">< i className="fa-solid fa-plus fa-beat "></i></button>
          </div>
        </div>
        
        <div className='mb-2'>
          <div className="input-group">
            <span className="input-group-text">
              <i className="fa-solid fa-search"></i>
            </span>
            <input className="form-control" aria-label="Search"
              type="text"
              placeholder="Buscar Usuario..."
            />
          </div>
        </div>

        <div className="my-1 d-flex justify-content-end mb-3 border-5">
          <Pagination
            className='pagination'
            current={page}
            total={totalPages}
            pageSize={1}
            onChange={onchangePage}
          />
        </div>

        {/* Mostrar tabla solo en dispositivos grandes (computadoras) */}
        <div className='d-none d-md-block'>
          <div className="table-responsive">
            <table className='table table-bordered border-1 table-hover mt-2'>
              {/* ... contenido de la tabla ... */}
              <thead>
                <tr style={{ background: "#008cba", color: "#ffffff" }}>
                  <th scope="col" className="responsive-text">#</th>
                  <th scope="col" className="responsive-text">Documento</th>
                  <th scope="col" className="responsive-text">Nombre Completo</th>
                  <th scope="col" className="responsive-text">Alias</th>
                  <th scope="col" className="responsive-text">Rol de Usuario</th>
                  <th scope="col" className="responsive-text">Télefono Celular</th>
                  <th scope="col" className="responsive-text">Correo electrónico</th>

                  <th scope="col" className="responsive-text">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(user) && user.map((item, i) => (
                  <tr key={item._id}>
                    <td className="responsive-text"> {i + 1}</td>
                    <td className="responsive-text"> {item.documentoUsuario} </td>
                    <td className="responsive-text"> {item.nombreUsuario} </td>
                    <td className='responsive-text'> {item.aliasUsuario} </td>
                    <td className="responsive-text"> {item.rolUsuario} </td>
                    <td className="responsive-text"> {item.telefonoUsuario} </td>
                    <td className="responsive-text"> {item.emailUsuario} </td>

                    <td>
                      <div className="btn-group btn-group-sm" role="group">
                        <>
                          {(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin') && (
                            <span className='btn btn-primary d-flex align-items-center me-2' title="Editar" onClick={() => editData(item)}>
                              <i className="fa-solid fa-pencil space-i"></i>
                            </span>
                          )}
                        </>
                        <>
                          {usuarios.rolUsuario === 'SuperAdmin' && (
                            <span className='btn btn-danger d-flex align-items-center' title="Eliminar" onClick={() => deleteUsuario(item._id)}>
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          )}
                        </>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Mostrar tarjetas solo en dispositivos pequeños (móviles) */}
        <div className='d-md-none'>
          {Array.isArray(user) && user.map((item, i) => (
            <div key={item._id} className='card border-3 mb-2'>
              {/* Contenido de la tarjeta */}
              <div className='card-body'>
                <h5 className='card-title'>Usuario{i + 1}</h5>
                <p className='card-text'>
                  <strong>Nit </strong> {item.documentoUsuario}<br />
                  <strong>Razón Social:</strong> {item.nombreUsuario}<br />
                  <strong>Alias:</strong>{item.aliasUsuario}<br />
                  <strong>Rol Usuario:</strong> {item.rolUsuario}<br />
                  <strong>Telefono:</strong> {item.telefonoUsuario}<br />
                  <strong>Correo Electronico:</strong>{item.emailUsuario}
                </p>
                <div className='btn-group btn-group-xl d-flex'>
                  <>
                    {(usuarios.rolUsuario === 'Admin' || usuarios.rolUsuario === 'SuperAdmin') && (
                      <span className='btn btn-primary d-flex align-items-center me-2' title="Editar" onClick={() => editData(item)}>
                        <i className="fa-solid fa-pencil space-i"></i>
                        <span className="ms-auto">Editar</span>
                      </span>
                    )}
                  </>
                  <>
                    {usuarios.rolUsuario === 'SuperAdmin' && (
                      <span className='btn btn-danger d-flex align-items-center' title="Eliminar" onClick={() => deleteUsuario(item._id)}>
                        <i className="fa-solid fa-trash"></i>
                        <span className="ms-auto">Eliminar</span>
                      </span>
                    )}
                  </>
                </div>

              </div>
            </div>
          ))}
        </div>
        <div className="my-1 d-flex justify-content-end mb-3 border-5">
          <Pagination
            className='pagination'
            current={page}
            total={totalPages}
            pageSize={1}
            onChange={onchangePage}
          />
        </div>
      </div >

    </div >



  )
}

export default Usuarios