import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import "./assets/css/index.css";
import Aplication from "./dashoboard/Aplication";
import Login from "./dashoboard/view/Login";
import Destinos from "./page/DestinosTuristicos";
import Home from './page/Home';
import Nosotros from './page/Nosotros';
import NotFound from './page/NotFound';
import PaquetesPorDestino from './page/PaquetesPorDestino';

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallBanner, setShowInstallBanner] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallBanner(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    setShowInstallBanner(false);
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const handleDismissClick = () => {
    setShowInstallBanner(false);
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="nosotros" element={<Nosotros />} />
        <Route path="destinos" element={<Destinos />} />
        <Route path="/destinos/:id/" element={<PaquetesPorDestino />} />
        <Route path="login" exact element={<Login />} />
        <Route exact path="/aplicacion/*" element={<Aplication />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {showInstallBanner && (
        <div id="install-banner" className="install-banner">
          <p>¿Deseas instalar la aplicación?</p>
          <button onClick={handleInstallClick}>Instalar</button>
          <button onClick={handleDismissClick}>No, gracias</button>
        </div>
      )}
    </div>
  );
}

export default App;
