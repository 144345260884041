import axios from 'axios';
import moment from 'moment';
import 'moment-timezone'; // Importa moment-timezone
import 'moment/locale/es'; // Importa el locale en español para moment
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Importa los estilos del calendario
import AlertadeViaje from '../components/AlertadeViaje';
import { useUsuarios } from '../context/UsuariosContext';
import { locales } from './Es'; // Importa las traducciones

const localizer = momentLocalizer(moment);

// Personaliza los textos de los botones y otros elementos del calendario
const messages = {
    allDay: locales.allDay,
    previous: locales.previous,
    next: locales.next,
    today: locales.today,
    month: locales.month.long,
    week: locales.week.long,
    day: locales.day.long,
    agenda: locales.agenda.long,
    noEvents: locales.noEvents,
};


const CalendarioReservas = () => {
    const { usuarios } = useUsuarios();
    const options = { headers: { authorization: "Bearer " + usuarios.token }, };


    const [reservas, setReservas] = useState([]);
    const [destinoNombres, setDestinoNombres] = useState({});

    useEffect(() => {
        // Función para obtener destinos
        const obtenerDestinos = async () => {
            try {
                const response = await axios.get('/api/destinos/listall', options);
                const destinosData = response.data.destinos;
                const nombres = {};
                destinosData.forEach((destino) => {
                    nombres[destino._id] = destino.nombreDestino;
                });
                setDestinoNombres(nombres);
            } catch (error) {
                console.error('Error al obtener la lista de destinos:', error);
            }
        };

        // Función para obtener reservas
        const fetchReservas = () => {
            axios.get('/api/reservas/listbydata', options)
                .then(response => {
                    const data = response.data;
                    if (data.ok) {
                        setReservas(data.reservas);
                    } else {
                        console.error('Error en la solicitud al servidor: ', data.message);
                    }
                })
                .catch(error => {
                    console.error('Error en la solicitud: ', error);
                });
        };

        obtenerDestinos();
        fetchReservas();
    }, []);
    

    // Ordenar las reservas por fecha de salida
    const sortedReservas = reservas.slice().sort((a, b) => {
        const dateA = new Date(a.fechaSalida.slice(0, 10));
        const dateB = new Date(b.fechaSalida.slice(0, 10));

        return dateB - dateA; // Orden descendente
    });

    // Función para generar un color de fondo aleatorio en formato hexadecimal
  

    // Convierte las reservas a eventos para el calendario
    const eventos = sortedReservas.map(reserva => {
        const fechaSalida = reserva.fechaSalida.slice(0, 10);
        const fechaLlegada = reserva.fechaLlegada.slice(0, 10);
    
        // Ajusta las horas para que el día inicie a las 00:00:00 y termine a las 23:59:59
        const inicioDia = new Date(fechaSalida);
        inicioDia.setHours(24, 59, 59, 999); // Establecer las horas a 00:00:00
    
        const finDia = new Date(fechaLlegada);
        finDia.setHours(24, 59, 59, 999); // Establecer las horas a 23:59:59
    
        return {
            title: reserva.destinos.map(destinoId => destinoNombres[destinoId] || 'Cargando...').join(', '),
            start: inicioDia,
            end: finDia,
            tooltip: `
                Reserva: ${reserva.numeroReserva}
                Fecha de Salida: ${fechaSalida} 
                Fecha de Llegada: ${fechaLlegada}
                Destinos: ${reserva.destinos.map(destinoId => destinoNombres[destinoId] || 'Cargando...').join(', ')}
                Pasajeros: ${reserva.npasajeros}
            `,
            fechaSalida,
            fechaLlegada,
            numeroReserva: reserva.numeroReserva,
         
        };
    });

    return (
        <div className="container mt-5">
            <h2>Calendario de Reservas</h2>
            <div className="calendar-container">
                <Calendar
                    localizer={localizer}
                    events={eventos}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    views={['month', 'day', 'week']}
                    messages={messages}
                    eventPropGetter={(event) => ({
                        style: {
                           
                            color: '#FFFFFF', // Color del texto en la barra
                            borderRadius: '5px',
                            padding: '4px',
                            border: 'none',
                        },
                        title: event.title,
                        tooltip: event.tooltip,
                    })}
                    eventTimeRangeFormat={{ month: '', week: '', day: '' }} // Oculta las horas
                    components={{
                        event: ({ event }) => (
                            <div
                                className="rbc-event-content d-flex justify-content-between align-items-center"
                                title={event.tooltip}
                            >
                                <span>{event.title}</span>
                                <span className="ms-2">{event.numeroReserva}</span>
                                <AlertadeViaje fechaSalida={event.fechaSalida} />
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default CalendarioReservas;
