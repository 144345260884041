import qrcode from 'qrcode';
import React, { useEffect, useState } from 'react';

const GenerateQRCode = () => {
    const [qrCode, setQrCode] = useState('');

    useEffect(() => {
        const generateQR = async () => {
            const urlCv = 'https://wa.me/573043635185?text=Necesito%20m%C3%A1s%20informaci%C3%B3n%20sobre%20planes%20tur%C3%ADsticos.%20Mensaje%20autom%C3%A1tico%20desde%20la%20p%C3%A1gina%20Web';
            try {
                const QR = await qrcode.toDataURL(urlCv);
                setQrCode(QR); // Guardar el código QR en el estado
            } catch (error) {
                console.error('Error generando el código QR:', error);
            }
        };

        generateQR();
    }, []);

    return (
        <div className="text-center mt-5">
            {qrCode ? (
                <img src={qrCode} alt="Código QR" />
            ) : (
                <p>Generando código QR...</p>
            )}
        </div>
    );
};

export default GenerateQRCode;
