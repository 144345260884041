import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';

const TextEditor = ({ setEditorContent, value, readOnly }) => {
  const handleChange = (html) => {
    setEditorContent(html);
  };

  return (
    <div className="text-editor-container">
      <ReactQuill
        value={value}
        onChange={handleChange}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
        placeholder="Escribe algo asombroso..."
        style={{ height: '300px' }} // Altura predeterminada
        readOnly={readOnly} // Establece el editor como solo lectura
      />
    </div>
  );
};


// Personaliza los módulos del editor
TextEditor.modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, false] }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    [{ align: [] }],
    ['link', 'image', 'video'],
    ['clean']
  ],
};

// Formatos que el editor soporta
TextEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'align',
  'link', 'image', 'video'
];

export default TextEditor;
