import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs ';
import { useUsuarios } from '../context/UsuariosContext';
import CalendarioReseravas from './CalendarioResedrvas';
import AlertadeViaje from '../components/AlertadeViaje'


const Home = () => {

  const { usuarios } = useUsuarios();
  const options = { headers: { authorization: "Bearer " + usuarios.token }, };

  const [totalClientes, setTotalClientes] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [totalReservas, setTotalReservas] = useState(0);
  const [totalCotizaciones, setTotalCotizaciones] = useState(0);


  // Función para realizar la solicitud HTTP
  const fetchTotalClientes = () => {
    axios.get('/api/clientes/listall', options)
      .then(response => {
        const data = response.data;
        if (data.ok) {
          setTotalClientes(data.totalClientes);
        } else {
          console.error('Error en la solicitud al servidor: ', data.message);
        }
      })
      .catch(error => {
        console.error('Error en la solicitud: ', error);
      });
  };

  // Función para obtener el total de reservas
  const fetchTotalReservas = () => {
    axios.get('/api/reservas/listall', options)
      .then(response => {
        const data = response.data;
        if (data.ok) {
          // Extrae los valores de la respuesta
          setTotalRegistros(data.totalRegistros);
          setTotalReservas(data.totalReservas);
          setTotalCotizaciones(data.totalCotizaciones);
        } else {
          console.error('Error en la solicitud al servidor: ', data.message);
        }
      })
      .catch(error => {
        console.error('Error en la solicitud: ', error);
      });
  };


  // Llama a fetchTotalClientes cuando el componente se monta
  useEffect(() => {
    fetchTotalClientes();
    fetchTotalReservas();
    obtenerDestinos();
    fetchReservas();
    fetchClientesByCurrentMonth();
  }, []);


  const [reservas, setReservas] = useState([]);
  const [destinoNombres, setDestinoNombres] = useState([]);

  const obtenerDestinos = async () => {
    try {
      const response = await axios.get('/api/destinos/listall', options);
      const destinosData = response.data.destinos;
      const nombres = {};
      destinosData.forEach((destino) => {
        nombres[destino._id] = destino.nombreDestino;
      });
      setDestinoNombres(nombres);
    } catch (error) {
      console.error('Error al obtener la lista de destinos:', error);
    }
  };

  // Función para obtener las reservas desde el servidor
  const fetchReservas = () => {
    axios.get('/api/reservas/listbydata', options)
      .then(response => {
        const data = response.data;
        if (data.ok) {
          setReservas(data.reservas);
        } else {
          console.error('Error en la solicitud al servidor: ', data.message);
        }
      })
      .catch(error => {
        console.error('Error en la solicitud: ', error);
      });
  };


  //----------------------------------------------------------------

  const [clientes, setClientes] = useState([]);

  // Función para obtener clientes cuyas fechas de nacimiento están en el mes actual
  const fetchClientesByCurrentMonth = async () => {
    try {
      const response = await axios.get('/api/clientes/current-month', options);
      const data = response.data;
      if (data.ok) {
        setClientes(data.clientes);
      } else {
        console.error('Error en la solicitud al servidor: ', data.message);
      }
    } catch (error) {
      console.error('Error en la solicitud: ', error);
    }
  };

  //----------------------------------------------------------------------------------------------

  


  //---------------------------------------------------------------------------------------------

  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

  // Filtrar las reservas para incluir solo las del mes actual
  const reservasDelMes = reservas.filter(reserva => {
    const fechaSalida = new Date(reserva.fechaSalida);
    return fechaSalida >= startOfMonth && fechaSalida <= endOfMonth;
  });

  // Ordenar las reservas filtradas por fechaSalida
  const sortedReservas = reservasDelMes.slice().sort((a, b) => {
    const dateA = new Date(a.fechaSalida);
    const dateB = new Date(b.fechaSalida);

    if (dateA > dateB) {
      return -1; // 'a' va antes que 'b'
    } else if (dateA < dateB) {
      return 1; // 'b' va antes que 'a'
    } else {
      return 0; // son iguales
    }
  });


  //---------------------------------------------------------------------------------------------

  const [currentPageClientes, setCurrentPageClientes] = useState(1); // Página actual para Clientes
  const recordsPerPageClientes = 10; // Registros por página para Clientes

  // Calcular el índice de los registros a mostrar para Clientes
  const indexOfLastRecordClientes = currentPageClientes * recordsPerPageClientes;
  const indexOfFirstRecordClientes = indexOfLastRecordClientes - recordsPerPageClientes;
  const currentRecordsClientes = clientes.slice(indexOfFirstRecordClientes, indexOfLastRecordClientes);

  // Cambiar de página para Clientes
  const paginateClientes = (pageNumber) => {
    setCurrentPageClientes(pageNumber);
  };

  // Calcular el total de páginas para Clientes
  const totalPagesClientes = Math.ceil(clientes.length / recordsPerPageClientes);

  //----------------------------------------------------------------
  const [currentPageDestinos, setCurrentPageDestinos] = useState(1); // Página actual para Destinos
  const recordsPerPageDestinos = 10; // Registros por página para Destinos

  // Convertir el objeto destinoNombres en un array para paginar
  const destinosArray = Object.values(destinoNombres);

  // Calcular el índice de los registros a mostrar para Destinos
  const indexOfLastRecordDestinos = currentPageDestinos * recordsPerPageDestinos;
  const indexOfFirstRecordDestinos = indexOfLastRecordDestinos - recordsPerPageDestinos;
  const currentRecordsDestinos = destinosArray.slice(indexOfFirstRecordDestinos, indexOfLastRecordDestinos);

  // Cambiar de página para Destinos
  const paginateDestinos = (pageNumber) => {
    setCurrentPageDestinos(pageNumber);
  };

  // Calcular el total de páginas para Destinos
  const totalPagesDestinos = Math.ceil(destinosArray.length / recordsPerPageDestinos);
  //---------------------------------------------------------------------------------------------

  const [currentPageReservas, setCurrentPageReservas] = useState(1); // Página actual para Reservas
  const recordsPerPageReservas = 10; // Registros por página para Reservas

  // Calcular el índice de los registros a mostrar para Reservas
  const indexOfLastRecordReservas = currentPageReservas * recordsPerPageReservas;
  const indexOfFirstRecordReservas = indexOfLastRecordReservas - recordsPerPageReservas;
  const currentRecordsReservas = reservas.slice(indexOfFirstRecordReservas, indexOfLastRecordReservas);

  // Cambiar de página para Reservas
  const paginateReservas = (pageNumber) => {
    setCurrentPageReservas(pageNumber);
  };

  // Calcular el total de páginas para Reservas
  const totalPagesReservas = Math.ceil(reservas.length / recordsPerPageReservas);

  //---------------------------------------------------------------------------------------------
  return (
    <div>
      <div className=" container" style={{ textAlign: 'left' }}>
        <Breadcrumbs />
      </div>

      <div className='container-md mt-5'>

        <div className="row">
          {/* Card 1 */}
          <div className="col-lg-3 col-6 mb-4">
            <div className="card text-white bg-info">
              <div className="card-body">
                <h3 className="card-title">{totalClientes}</h3>
                <p className="card-text">Total Clientes</p>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <Link className="text-white">Más Info</Link>
                <i className="fas fa-arrow-circle-right"></i>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-lg-3 col-6 mb-4">
            <div className="card text-white bg-success">
              <div className="card-body">
                <h3 className="card-title">{totalReservas}</h3>
                <p className="card-text">Total Reservas</p>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <Link className="text-white">Más Info</Link>
                <i className="fas fa-arrow-circle-right"></i>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-lg-3 col-6 mb-4">
            <div className="card text-white bg-warning">
              <div className="card-body">
                <h3 className="card-title">{totalRegistros}</h3>
                <p className="card-text">Total Registros</p>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <Link className="text-white">Más Info</Link>
                <i className="fas fa-arrow-circle-right"></i>
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className="col-lg-3 col-6 mb-4">
            <div className="card text-white bg-danger">
              <div className="card-body">
                <h3 className="card-title">{totalCotizaciones}</h3>
                <p className="card-text">Total Cotizaciones</p>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <Link className="text-white">Más Info</Link>
                <i className="fas fa-arrow-circle-right"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="row">

          <div className="col-md-4 mb-4 d-flex">
            <div className="card shadow flex-fill">
              <div className="card-body d-flex flex-column">
                <div className="card container text-center mb-2">
                  <div className="row align-items-center mt-3 mb-3">
                    <div className="col-1 d-flex align-items-center justify-content-start">
                      <i className="fa-solid fa-cake-candles fa-2xl"></i>
                    </div>
                    <div className="col-10 d-flex align-items-center justify-content-center">
                      <h5><strong>Cumpleañeros del Mes</strong></h5>
                    </div>
                  </div>
                </div>

                <div className="card container text-center flex-fill d-flex flex-column fixed-height">
                  <div className="row align-items-center justify-content-center flex-fill">
                    <ul>
                      {currentRecordsClientes.map((cliente) => (  // Cambié a currentRecordsClientes
                        <ol key={cliente._id}>
                          {cliente.nombre1Cliente} {cliente.apellido1Cliente} - {cliente.fechanacimientoCliente.slice(0, 10)}
                        </ol>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='mt-2'>
                  <div className="pagination">
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        {Array.from({ length: totalPagesClientes }, (_, index) => (  // Cambié a totalPagesClientes
                          <li className={`page-item ${currentPageClientes === index + 1 ? 'active' : ''}`} key={index + 1}>
                            <button
                              className="page-link"
                              onClick={() => paginateClientes(index + 1)}  // Cambié a paginateClientes
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4 d-flex">
            <div className="card shadow flex-fill">
              <div className="card-body d-flex flex-column">

                <div className="card container text-center mb-2">
                  <div className="row align-items-center mt-3 mb-3">
                    <div className="col-1 d-flex align-items-center justify-content-start">
                      <i className="fa-solid fa-plane fa-2xl"></i>
                    </div>
                    <div className="col-10 d-flex align-items-center justify-content-center">
                      <h5><strong>Nuestros Destinos</strong></h5>
                    </div>
                  </div>
                </div>

                <div className="card container text-center flex-fill d-flex flex-column fixed-height">
                  <div className="row align-items-center justify-content-center flex-fill">
                    <ul className="list-unstyled">
                      {currentRecordsDestinos.map((nombre, index) => (
                        <li key={index} className="text-center">
                          {nombre}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='mt-2'>
                  <div className="pagination">
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        {Array.from({ length: totalPagesDestinos }, (_, index) => (
                          <li className={`page-item ${currentPageDestinos === index + 1 ? 'active' : ''}`} key={index + 1}>
                            <button
                              className="page-link"
                              onClick={() => paginateDestinos(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="col-md-4 mb-4 d-flex">
            <div className="card shadow flex-fill">
              <div className="card-body d-flex flex-column">

                <div className="card container text-center mb-2">
                  <div className="row align-items-center mt-3 mb-3">
                    <div className="col-1 d-flex align-items-center">
                      <i className="fa-solid fa-hotel fa-2xl"></i>
                    </div>
                    <div className="col-10 d-flex align-items-center justify-content-center">
                      <h5><strong>Reservas activas</strong></h5>
                    </div>
                  </div>
                </div>


                <div className="card container text-center flex-fill d-flex flex-column fixed-height">
                  <div className="row align-items-center justify-content-center">
                    <table>
                      <thead>
                        <tr>
                          <th>Fecha de Salida</th>
                          <th>Alerta</th>
                          <th>Destino</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedReservas.map((reserva, index) => (
                          <tr key={index}>
                            <td>{reserva.fechaSalida.slice(0, 10)}</td>
                            <td>
                              <AlertadeViaje fechaSalida={reserva.fechaSalida.slice(0, 10)} />
                            </td>
                            <td>
                              {reserva.destinos.map((destinoId, dIndex) => (
                                <span key={dIndex}>
                                  {destinoNombres[destinoId] || 'Cargando...'}
                                  {dIndex < reserva.destinos.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>


                <div className='mt-2'>
                  <div className="pagination">
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        {Array.from({ length: totalPagesReservas }, (_, index) => (
                          <li className={`page-item ${currentPageReservas === index + 1 ? 'active' : ''}`} key={index + 1}>
                            <button
                              className="page-link"
                              onClick={() => paginateReservas(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div className='row'>

          <CalendarioReseravas />

        </div>

      </div>
    </div>


  )
}

export default Home