import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom'; // Importa Navigate para redireccionamiento
import NavbarDashboard from '../dashoboard/components/NavbarDashboard';
import PagoaportesPdf from './components/PagoaportesPdf';
import ReservasPdf from './components/ReservasPdf';
import { useUsuarios } from './context/UsuariosContext'; // Importa el hook useUsuarios desde tu contexto de usuarios
import Cliente from './view/Cliente';
import Destinos from './view/Destinos';
import Help from './view/Help';
import Home from './view/Home';
import Login from './view/Login';
import Paquetes from './view/Paquetes';
import Proveedores from './view/Proveedores';
import Reservas from './view/Reservas';
import Usuarios from './view/Usuarios';


const Application = () => {
  const { usuarios } = useUsuarios();

  return (
    <div>
      <NavbarDashboard/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        {/* Protege las rutas privadas */}
        {usuarios.login ? (
          <>
            <Route path="destinos" element={<Destinos />} />
            <Route path="cliente" element={<Cliente />} />
            <Route path="proveedores" element={<Proveedores />} />
            <Route path="paquetes" element={<Paquetes />} />
            <Route path="reservas" element={<Reservas />} />
            <Route path="/reservas/:id" element={<ReservasPdf/>} />
            <Route path="/reservas/pagoaportes/:id" element={<PagoaportesPdf/>} />
            
            <Route path="usuarios" element={<Usuarios />} />
            <Route path="ayuda" element={<Help />} />
          </>
        ) : (
          null // No necesitas redirigir aquí, ya que las rutas no estarán disponibles para usuarios no autenticados
        )}
      </Routes>
      {/* Redirige a /login si el usuario intenta acceder a una ruta no definida */}
      {!usuarios.login && <Navigate to="/login" replace />}
    </div>
  );
};

export default Application;
