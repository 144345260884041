import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loading } from '../dashoboard/view/Loading';
import Footer from '../page/Footer';
import Navibar from '../page/Navibar';

const DestinosTuristicos = () => {
  const [destinos, setDestinos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [ubicaciones, setUbicaciones] = useState([]);
  const [nombres, setNombres] = useState([]);
  const [ubicacionFiltro, setUbicacionFiltro] = useState('');
  const [nombreFiltro, setNombreFiltro] = useState('');
  const [busqueda, setBusqueda] = useState('');

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (ubicacionFiltro) {
      // Filtrar los nombres de destinos basados en la ubicación seleccionada
      const nombresUnicos = [...new Set(
        destinos
          .filter(destino => destino.ubicacionDestino === ubicacionFiltro)
          .map(destino => destino.nombreDestino)
      )];
      setNombres(nombresUnicos);
      setNombreFiltro(''); // Reiniciar el filtro de nombre cuando cambia la ubicación
    } else {
      setNombres([]);
      setNombreFiltro('');
    }
  }, [ubicacionFiltro, destinos]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get("/api/destinos/listall");
      const destinosActivos = data.destinos.filter(destino => destino.estadoDestino === 'Activo');
      setDestinos(destinosActivos);

      // Extraer ubicaciones únicas
      const ubicacionesUnicas = [...new Set(destinosActivos.map(destino => destino.ubicacionDestino))];
      setUbicaciones(ubicacionesUnicas);

      setError('');
    } catch (error) {
      console.log("error en la función getData", error.message);
      setError(error.message);
      alert(error.response?.data?.mensaje || 'Error al obtener los datos');
    } finally {
      setLoading(false);
    }
  };

  const handleUbicacionChange = (e) => {
    setUbicacionFiltro(e.target.value);
  };

  const handleNombreChange = (e) => {
    setNombreFiltro(e.target.value);
  };

  const handleBusquedaChange = (e) => {
    setBusqueda(e.target.value);
  };

  // Filtrar destinos por ubicación y nombre
  const destinosFiltrados = destinos.filter((destino) =>
    (ubicacionFiltro === '' || destino.ubicacionDestino === ubicacionFiltro) &&
    (nombreFiltro === '' || destino.nombreDestino === nombreFiltro) &&
    (busqueda === '' ||
      destino.nombreDestino.toLowerCase().includes(busqueda.toLowerCase()) ||
      destino.ubicacionDestino.toLowerCase().includes(busqueda.toLowerCase()))
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Navibar />
      <div className='container-md mt-5'>
        <div className='heading text-center mb-5'>
          <h2 className='fw-bold'>Nuestros Destinos Turísticos</h2>
        </div>
        <div className='row mb-3'>
          <div className='col-12 col-md-12'>
            <div className="input-group">
              <input
                type='text'
                value={busqueda}
                onChange={handleBusquedaChange}
                className='form-control'
                placeholder='Busca tu país o destino de preferencia'
              />
              <div className="input-group-append">
                <span className="input-group-text d-flex align-items-center" style={{ height: '100%' }}>
                  <i className="fa fa-search"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-12 col-md-6 mb-2 mb-3'>
            <select
              value={ubicacionFiltro}
              onChange={handleUbicacionChange}
              className='form-select'
            >
              <option value=''>Selecciona una ubicación</option>
              {ubicaciones.map((ubicacion, index) => (
                <option key={index} value={ubicacion}>
                  {ubicacion}
                </option>
              ))}
            </select>
          </div>
          <div className='col-12 col-md-6'>
            <select
              value={nombreFiltro}
              onChange={handleNombreChange}
              className='form-select'
              disabled={!ubicacionFiltro}
            >
              <option value=''>Selecciona un destino</option>
              {ubicacionFiltro && nombres.length > 0 ? (
                nombres.map((nombre, index) => (
                  <option key={index} value={nombre}>
                    {nombre}
                  </option>
                ))
              ) : (
                <option value=''>Primero selecciona una ubicación</option>
              )}
            </select>
          </div>
        </div>

        <div className='card-galery mt-4 mb-5'>
          <div className='container'>
            <div className='row row-cols-1 row-cols-md-3 g-4'>
              {Array.isArray(destinosFiltrados) && destinosFiltrados.map((item) => (
                <div className='col-12 col-md-6 col-xl-4' key={item._id}>
                  <div className='card h-100 border-0 transform-on-hover shadow zoom-on-hover'>
                    <div className="card-container">
                      <Link to={`/destinos/${item._id}/`}>
                        <img src={item.img} className='card-img-top' alt={item.nombreDestino} title={`Haz clic aquí para ir a ${item.nombreDestino}`}/>
                      </Link>
                    </div>
                    <div className='card-body'>
                      <h6 className='card-title fw-bold'>{item.nombreDestino}</h6>
                      <p className='card-text'>{item.ubicacionDestino}</p>
                      <p className='card-text'>{item.descripcionDestino}</p>
                    </div>
                    <Link
                      to={`/destinos/${item._id}/`}
                      className='btn colormenu'
                      style={{ color: 'white', fontWeight: 'bold' }} title={`Haz clic aquí para ir a ${item.nombreDestino}`}
                    >
                      <strong><h5>Ver Paquetes</h5></strong>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DestinosTuristicos;
