import qrcode from 'qrcode';
import React, { useEffect, useState } from 'react';

const WebGenerateQRrecibo = ({ numeroReciboFormateado }) => {
    const [qrCode, setQrCode] = useState('');

    useEffect(() => {
        const generateQR = async () => {
            try {
                // Generar la URL que contiene el número de recibo formateado
                const qrData = `https://example.com/recibo/${numeroReciboFormateado}`;

                // Generar el código QR a partir de la URL
                const QR = await qrcode.toDataURL(qrData);
                setQrCode(QR); // Guardar el código QR en el estado
            } catch (error) {
                console.error('Error generando el código QR:', error);
            }
        };

        generateQR();
    }, [numeroReciboFormateado]);

    return (
        <div className="text-center">
            {qrCode ? (
                <img src={qrCode} alt="Código QR" />
            ) : (
                <p>Generando código QR...</p>
            )}
        </div>
    );
};

export default WebGenerateQRrecibo;