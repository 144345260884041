import axios from 'axios';
import 'rc-pagination/assets/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { UsuariosProvider } from './dashoboard/context/UsuariosContext';
import './index.css';


//axios.defaults.baseURL = ('http://localhost:4000')

axios.defaults.baseURL = ('http://85.31.235.16:4000')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <UsuariosProvider>
      <App />
    </UsuariosProvider>
  </BrowserRouter>
);

