import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUsuarios } from '../context/UsuariosContext';
import LogoColor from '../Images/LogoColor.png';
import WebGenerateQRrecibo from './WebGenerateQRrecibo';


// Definir estilos
const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        padding: 2,
    },
    logo: {
        width: 210,
        height: 80,
        resizeMode: 'contain',
    },
    card: {
        padding: 10,
    },
    agenciaText: {
        fontWeight: 'bold',
        fontSize: 14, // Ajustado a 14px
        marginBottom: 4, // Espacio entre los textos
    },
    rntNitText: {
        fontWeight: 'bold',
        fontSize: 10, // Ajustado a 10px
        marginBottom: 4, // Espacio entre los textos
    },
    reciboText: {
        fontSize: 12, // Ajustado a 15px
        fontWeight: 'bold',
    },
    horizontalLine: {
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        marginVertical: 10,
    },
    section: {
        marginVertical: 10,
        marginHorizontal: 15, // Agrega margen izquierdo y derecho
        borderWidth: 1, // Grosor del borde
        borderColor: 'black', // Color del borde
        borderRadius: 5, // Radio del borde para esquinas redondeadas (opcional)
        padding: 10, // Espacio interno para que el contenido no esté pegado al borde
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    boldText: {
        strong: 'black',
        fontWeight: 'bold',
        fontSize: 10,
    },
    normalText: {
        fontSize: 10,
    },
    secciondetallerecibo: {
        marginVertical: 10,
        marginHorizontal: 15, // Agrega margen izquierdo y derecho
        borderWidth: 1, // Grosor del borde
        borderColor: 'black', // Color del borde
        borderRadius: 5, // Radio del borde para esquinas redondeadas (opcional)
        padding: 10, // Espacio interno para que el contenido no esté pegado al borde
    },
    table: {
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
    },
    tableCol: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
    },
    tableCell: {
        fontSize: 12,
    },
    footer: {
        textAlign: 'center',
        fontSize: 8,
        marginTop: 5,
        color: '#555',
    },

    dashedLine: {
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        borderBottomStyle: 'dashed',
        marginTop: 20,
    },

});

const MyDocument = ({ pagoAporte, nombresPaquetes, nombresDestinos }) => (

    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View style={{ width: '50%' }}>
                    <Image source={LogoColor} style={styles.logo} />
                    <Text style={styles.boldText}>ORIGINAL</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <View style={styles.card}>
                        <Text style={styles.agenciaText}>Agencia Jukumaritravels</Text>
                        <Text style={styles.rntNitText}>RNT 138002 - NIT 71227531-3</Text>
                        <Text style={styles.reciboText}>RECIBO: {formatNumeroRecibo(pagoAporte.pagoAporte.numeroRecibo)}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.horizontalLine} />

            <View style={styles.section}>
                <View style={styles.row}>
                    <Text style={styles.boldText}>LUGAR Y FECHA:</Text>
                    <Text style={styles.normalText}>Santa Rosa de Osos - (Ant), {new Date(pagoAporte.pagoAporte.fechaAporte).toISOString().slice(0, 10)}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.boldText}>CLIENTE:</Text>
                    <View>
                        {pagoAporte.clientes.map(cliente => (
                            <Text key={cliente._id} style={styles.normalText}>
                                {cliente.nombre1Cliente} {cliente.apellido1Cliente}
                            </Text>
                        ))}
                    </View>
                    <Text style={styles.boldText}>DOCUMENTO:</Text>
                    <View>
                        {pagoAporte.clientes.map(cliente => (
                            <Text key={cliente._id} style={styles.normalText}>
                                {cliente.documentoCliente}
                            </Text>
                        ))}
                    </View>
                </View>

                <View style={styles.row}>
                    <Text style={styles.boldText}>EMAIL:</Text>
                    <View>
                        {pagoAporte.clientes.map(cliente => (
                            <Text key={cliente._id} style={styles.normalText}>
                                {cliente.emailCliente}
                            </Text>
                        ))}
                    </View>
                    <Text style={styles.boldText}>TELEFONO:</Text>
                    <View>
                        {pagoAporte.clientes.map(cliente => (
                            <Text key={cliente._id} style={styles.normalText}>
                                {cliente.telefono1Cliente}
                            </Text>
                        ))}
                    </View>
                </View>
            </View>

            <View style={styles.section}>
                <View style={styles.row}>
                    <Text style={styles.boldText}>DESTINO:</Text>
                    <View>
                        {pagoAporte.destinos.map(destino => (
                            <Text key={destino._id} style={styles.normalText}>
                                {destino.nombreDestino}
                            </Text>
                        ))}
                    </View>
                    <Text style={styles.boldText}>PAQUETE:</Text>
                    <View>
                        {pagoAporte.paquetesturisticos.map(paquete => (
                            <Text key={paquete._id} style={styles.normalText}>
                                {paquete.nombrePaqueteTuristico}
                            </Text>
                        ))}
                    </View>
                </View>
            </View>

            <View style={styles.section}>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { flex: 1 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>CANT</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 6 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>CONCEPTO</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>FORMA DE PAGO</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>SUBTOTAL</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { flex: 1 }]}>
                            <Text style={styles.tableCell}>1</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 6 }]}>
                            <Text style={styles.normalText}>
                                Pago aporte compra paquete turístico {nombresPaquetes}.
                            </Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={styles.tableCell}>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.mediodepagoAporte
                                    ? pagoAporte.pagoAporte.mediodepagoAporte.toUpperCase()
                                    : 'No Registra'}
                            </Text>

                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={styles.tableCell}>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte !== null && pagoAporte.pagoAporte.valorAporte !== undefined
                                    ? Number(pagoAporte.pagoAporte.valorAporte).toLocaleString('es-CO', {
                                        style: 'currency',
                                        currency: 'COP',
                                        minimumFractionDigits: 2
                                    })
                                    : '0'}
                            </Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>

                        <View style={[styles.tableCol, { flex: 8 }]}>
                            <Text style={styles.normalText}>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte
                                    ? numeroEnLetras(parseFloat(pagoAporte.pagoAporte.valorAporte))
                                    : 'No Registra'}
                            </Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>TOTAL</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={styles.tableCell}>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte !== null && pagoAporte.pagoAporte.valorAporte !== undefined
                                    ? Number(pagoAporte.pagoAporte.valorAporte).toLocaleString('es-CO', {
                                        style: 'currency',
                                        currency: 'COP',
                                        minimumFractionDigits: 2
                                    })
                                    : '0'}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>

            <View style={styles.footer}>
                <Text>Este recibo de caja se asimila en sus efectos legales a una letra de cambio según artículo 774 del código de comercio y presta mérito ejecutivo.</Text>
            </View>

            <View style={styles.dashedLine} />

            <View style={styles.header}>
                <View style={{ width: '50%' }}>
                    <Image source={LogoColor} style={styles.logo} />
                    <Text style={styles.boldText}>COPIA</Text>
                </View>
                <View style={{ width: '50%' }}>
                    <View style={styles.card}>
                        <Text style={styles.agenciaText}>Agencia Jukumaritravels</Text>
                        <Text style={styles.rntNitText}>RNT 138002 - NIT 71227531-3</Text>
                        <Text style={styles.reciboText}>RECIBO: {formatNumeroRecibo(pagoAporte.pagoAporte.numeroRecibo)}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.horizontalLine} />

            <View style={styles.section}>
                <View style={styles.row}>
                    <Text style={styles.boldText}>LUGAR Y FECHA:</Text>
                    <Text style={styles.normalText}>Santa Rosa de Osos - (Ant), {new Date(pagoAporte.pagoAporte.fechaAporte).toISOString().slice(0, 10)}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.boldText}>CLIENTE:</Text>
                    <View>
                        {pagoAporte.clientes.map(cliente => (
                            <Text key={cliente._id} style={styles.normalText}>
                                {cliente.nombre1Cliente} {cliente.apellido1Cliente}
                            </Text>
                        ))}
                    </View>
                    <Text style={styles.boldText}>DOCUMENTO:</Text>
                    <View>
                        {pagoAporte.clientes.map(cliente => (
                            <Text key={cliente._id} style={styles.normalText}>
                                {cliente.documentoCliente}
                            </Text>
                        ))}
                    </View>
                </View>

                <View style={styles.row}>
                    <Text style={styles.boldText}>EMAIL:</Text>
                    <View>
                        {pagoAporte.clientes.map(cliente => (
                            <Text key={cliente._id} style={styles.normalText}>
                                {cliente.emailCliente}
                            </Text>
                        ))}
                    </View>
                    <Text style={styles.boldText}>TELEFONO:</Text>
                    <View>
                        {pagoAporte.clientes.map(cliente => (
                            <Text key={cliente._id} style={styles.normalText}>
                                {cliente.telefono1Cliente}
                            </Text>
                        ))}
                    </View>
                </View>
            </View>

            <View style={styles.section}>
                <View style={styles.row}>
                    <Text style={styles.boldText}>DESTINO:</Text>
                    <View>
                        {pagoAporte.destinos.map(destino => (
                            <Text key={destino._id} style={styles.normalText}>
                                {destino.nombreDestino}
                            </Text>
                        ))}
                    </View>
                    <Text style={styles.boldText}>PAQUETE:</Text>
                    <View>
                        {pagoAporte.paquetesturisticos.map(paquete => (
                            <Text key={paquete._id} style={styles.normalText}>
                                {paquete.nombrePaqueteTuristico}
                            </Text>
                        ))}
                    </View>
                </View>
            </View>

            <View style={styles.section}>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { flex: 1 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>CANT</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 6 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>CONCEPTO</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>FORMA DE PAGO</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>SUBTOTAL</Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { flex: 1 }]}>
                            <Text style={styles.tableCell}>1</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 6 }]}>
                            <Text style={styles.normalText}>
                                Pago aporte compra paquete turístico {nombresPaquetes}.
                            </Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={styles.tableCell}>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.mediodepagoAporte
                                    ? pagoAporte.pagoAporte.mediodepagoAporte.toUpperCase()
                                    : 'No Registra'}
                            </Text>

                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={styles.tableCell}>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte !== null && pagoAporte.pagoAporte.valorAporte !== undefined
                                    ? Number(pagoAporte.pagoAporte.valorAporte).toLocaleString('es-CO', {
                                        style: 'currency',
                                        currency: 'COP',
                                        minimumFractionDigits: 2
                                    })
                                    : '0'}
                            </Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>

                        <View style={[styles.tableCol, { flex: 8 }]}>
                            <Text style={styles.normalText}>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte
                                    ? numeroEnLetras(parseFloat(pagoAporte.pagoAporte.valorAporte))
                                    : 'No Registra'}
                            </Text>

                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={[styles.tableCell, styles.boldText]}>TOTAL</Text>
                        </View>
                        <View style={[styles.tableCol, { flex: 2 }]}>
                            <Text style={styles.tableCell}>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte !== null && pagoAporte.pagoAporte.valorAporte !== undefined
                                    ? Number(pagoAporte.pagoAporte.valorAporte).toLocaleString('es-CO', {
                                        style: 'currency',
                                        currency: 'COP',
                                        minimumFractionDigits: 2
                                    })
                                    : '0'}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>

            <View style={styles.footer}>
                <Text>Este recibo de caja se asimila en sus efectos legales a una letra de cambio según artículo 774 del código de comercio y presta mérito ejecutivo.</Text>
            </View>



        </Page>
    </Document>
);


const PdfPreview = ({ pagoAporte, nombresPaquetes, nombresDestinos }) => (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <MyDocument pagoAporte={pagoAporte} nombresPaquetes={nombresPaquetes} nombresDestinos={nombresDestinos} />
    </PDFViewer>
);

const convertirNumeroMenorCien = (numero) => {
    const unidades = ['cero', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const decenas = ['', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const teens = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
    if (numero < 10) return unidades[numero];
    if (numero < 20) return teens[numero - 10];
    if (numero < 100) {
        return decenas[Math.floor(numero / 10)] + (numero % 10 > 0 ? ' y ' + unidades[numero % 10] : '');
    }
    return '';
};

const convertirNumero = (numero) => {
    const centenas = ['', 'cien', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];
    if (numero === 0) {
        return 'cero';
    } else if (numero < 100) {
        return convertirNumeroMenorCien(numero);
    } else if (numero < 1000) {
        const centena = Math.floor(numero / 100);
        const resto = numero % 100;
        const numTexto = centena === 1 && resto === 0 ? 'cien' : centenas[centena];
        if (resto !== 0) {
            return numTexto + ' ' + convertirNumero(resto);
        }
        return numTexto;
    } else if (numero < 1000000) {
        const miles = Math.floor(numero / 1000);
        const resto = numero % 1000;
        const numTexto = miles === 1 ? 'mil' : convertirNumero(miles) + ' mil';
        if (resto !== 0) {
            return numTexto + ' ' + convertirNumero(resto);
        }
        return numTexto;
    } else {
        return 'Número fuera de rango';
    }
};

const numeroEnLetras = (numero) => {
    return `${convertirNumero(numero)} Pesos moneda corriente (MCTE) Colombiana (COP)`;
};
function formatNumeroRecibo(numero) {
    // Si numero es undefined o null, usa '0' como valor predeterminado
    const numeroStr = (numero ?? 'NO VALIDO').toString();
    // Usa padStart para agregar ceros hasta que la longitud sea 12
    return numeroStr.padStart(12, '0');
}

const PagoaportesPdf = () => {


    const { id } = useParams();
    const [pagoAporte, setPagoAporte] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { usuarios } = useUsuarios([]);
    const options = { headers: { authorization: "Bearer " + usuarios.token }, };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/api/reservas/pagoaportes/${id}`, options);
                console.log('Response from API:', response);

                if (response.data.ok) {
                    setPagoAporte(response.data);
                } else {
                    setError('No se encontraron datos');
                }
            } catch (error) {
                console.error('Error al obtener los datos del pago:', error);
                setError('Error al obtener los datos del pago');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return <div>Cargando...</div>; // Muestra un indicador de carga mientras se obtienen los datos
    }

    if (error) {
        return <div>{error}</div>; // Muestra un mensaje de error si ocurre
    }

    if (!pagoAporte) {
        return <div>No se encontró el pago</div>; // Maneja el caso en el que la reserva no existe
    }


    const nombresPaquetes = pagoAporte.paquetesturisticos.map(paquete => paquete.nombrePaqueteTuristico).join(', ');
    const nombresDestinos = pagoAporte.destinos.map(destino => destino.nombreDestino).join(', ');







    /* const generateAndDownloadPDF = async () => {
        const { pdf } = await import('@react-pdf/renderer');

        const blob = await pdf(
            <MyDocument
                pagoAporte={pagoAporte}
                nombresPaquetes={pagoAporte.paquetesturisticos.map(paquete => paquete.nombrePaqueteTuristico).join(', ')}
                nombresDestinos={pagoAporte.destinos.map(destino => destino.nombreDestino).join(', ')}
            />
        ).toBlob();

        const currentDate = new Date();
        const year = currentDate.getFullYear().toString();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');

        const fileName = `JKT-RECIBO${year}${month}${day}-${hours}${minutes}${seconds}.pdf`;

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        link.click();
    }; */

    const generateAndDownloadPDF = async () => {
        const { pdf } = await import('@react-pdf/renderer');

        const blob = await pdf(
            <MyDocument
                pagoAporte={pagoAporte}
                nombresPaquetes={pagoAporte.paquetesturisticos.map(paquete => paquete.nombrePaqueteTuristico).join(', ')}
                nombresDestinos={pagoAporte.destinos.map(destino => destino.nombreDestino).join(', ')}
            />
        ).toBlob();

        // Usa el número de recibo para el nombre del archivo
        const numeroRecibo = formatNumeroRecibo(pagoAporte.pagoAporte.numeroRecibo);
        const fileName = `JKT-RECIBO-${numeroRecibo}.pdf`;

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        link.click();
    };


    return (
        <div className="container">
            <div className="container mt-5 justify-content-end mt-3 mb-3 d-none d-lg-block">
                <div className="col text-end">
                    <button className="btn btn-success btn-lg" onClick={generateAndDownloadPDF} >
                        Generar PDF
                        <i className="btn fa-solid fa-file-pdf fa-2xl text-white"></i>
                    </button>
                </div>
            </div>

            <div className="container mt-5 mb-3 d-lg-none">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 text-center">
                        <button className="btn btn-success btn-lg w-100" onClick={generateAndDownloadPDF} >
                            Generar PDF
                            <i className="btn fa-solid fa-file-pdf fa-2xl text-white"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                {/* Sección para pantallas grandes */}
                <div className="d-none d-md-block">
                    <div className="row invoice-header align-items-center">
                        <div className="col-md-6 d-flex align-items-center justify-content-center mb-3 mb-md-0">
                            <img src={LogoColor} alt="Logo" style={{ maxWidth: '50%' }} />
                        </div>
                        <div className="col-md-5 text-center mb-3 mb-md-0">
                            <div className="card">
                                <div className="card-header">
                                    <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>Agencia Jukumaritravels</div>
                                    <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>RNT 138002 - NIT 71227531-3</div>
                                </div>
                                <div className="card-body">
                                    <h4>RECIBO: <span>{formatNumeroRecibo(pagoAporte.pagoAporte.numeroRecibo)}</span></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-flex align-items-center justify-content-center">
                            <WebGenerateQRrecibo idPagoAporte={pagoAporte.pagoAporte._id} />
                        </div>

                    </div>

                    <hr />

                    <div className="border border-2 mb-3 p-3">
                        <div className="row mt-3">
                            <div className="col-12 col-md-2">
                                <h6><strong>LUGAR Y FECHA:</strong></h6>
                            </div>
                            <div className="col-12 col-md-5">
                                <h6>Santa Rosa de Osos - (Ant), <span>{new Date(pagoAporte.pagoAporte.fechaAporte).toISOString().slice(0, 10)}</span></h6>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-2">
                                <h6><strong>CLIENTE:</strong></h6>
                            </div>
                            <div className="col-12 col-md-5">
                                {pagoAporte.clientes.map(cliente => (
                                    <div key={cliente._id}>
                                        <h6>{cliente.nombre1Cliente} {cliente.apellido1Cliente}</h6>
                                    </div>
                                ))}
                            </div>
                            <div className="col-12 col-md-2">
                                <h6><strong>DOCUMENTO:</strong></h6>
                            </div>
                            <div className="col-12 col-md-3">
                                {pagoAporte.clientes.map(cliente => (
                                    <div key={cliente._id}>
                                        <h6>{cliente.documentoCliente}</h6>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-2">
                                <h6><strong>EMAIL:</strong></h6>
                            </div>
                            <div className="col-12 col-md-5">
                                {pagoAporte.clientes.map(cliente => (
                                    <div key={cliente._id}>
                                        <h6>{cliente.emailCliente}</h6>
                                    </div>
                                ))}
                            </div>
                            <div className="col-12 col-md-2">
                                <h6><strong>TELEFONO:</strong></h6>
                            </div>
                            <div className="col-12 col-md-3">
                                {pagoAporte.clientes.map(cliente => (
                                    <div key={cliente._id}>
                                        <h6>{cliente.telefono1Cliente}</h6>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="border border-2 mb-2">
                        <div className="row mt-4 mb-3">
                            <div className="col-2">
                                <h6><strong>DESTINO:</strong></h6>
                            </div>
                            <div className="col-2">
                                <h6>{pagoAporte.destinos.map(destino => (
                                    <div key={destino._id}>
                                        <p>{destino.nombreDestino}</p>
                                    </div>
                                ))}</h6>
                            </div>
                            <div className="col-2">
                                <h6><strong>PAQUETE:</strong></h6>
                            </div>
                            <div className="col-6">
                                <h6>
                                    {pagoAporte.paquetesturisticos.map(paquete => (
                                        <div key={paquete._id}>
                                            <p>{paquete.nombrePaqueteTuristico}</p>
                                        </div>
                                    ))}
                                </h6>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className='mb-3'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className="text-center">
                                    <th className='col-1'><h6><strong>CANTIDAD</strong></h6></th>
                                    <th className='col-6'><h6><strong>COCNEPTO</strong></h6></th>
                                    <th className='col-2'><h6><strong>FORMA DE PAGO</strong></h6></th>
                                    <th className='col-2'><h6><strong>TOTAL</strong></h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="col-1 text-center">1</td>
                                    <td className='col-6'> <p>Pago aporte compra paquete turistico {nombresPaquetes}.</p> </td>
                                    <td className="col-2 text-end">
                                        {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.mediodepagoAporte
                                            ? pagoAporte.pagoAporte.mediodepagoAporte.toUpperCase()
                                            : 'No Registra'}
                                    </td>

                                    <td className="col-2 text-end">
                                        {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte !== null && pagoAporte.pagoAporte.valorAporte !== undefined
                                            ? Number(pagoAporte.pagoAporte.valorAporte).toLocaleString('es-CO', {
                                                style: 'currency', currency: 'COP', minimumFractionDigits: 2
                                            })
                                            : '0'}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="3" className="text-end"><strong>TOTAL</strong></td>
                                    <td className="col-2 text-end">
                                        {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte !== null && pagoAporte.pagoAporte.valorAporte !== undefined
                                            ? Number(pagoAporte.pagoAporte.valorAporte).toLocaleString('es-CO', {
                                                style: 'currency', currency: 'COP', minimumFractionDigits: 2
                                            })
                                            : '0'}
                                    </td>

                                </tr>
                                <tr>
                                    <td colSpan="4" className="text-end">
                                        {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte
                                            ? numeroEnLetras(parseFloat(pagoAporte.pagoAporte.valorAporte))
                                            : 'No Registra'}
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="alert alert-info">
                                <h6>"LA ALTERACIÓN, FALSIFICACIÓN O COMERCIALIZACIÓN ILEGAL DE ESTE DOCUMENTO ESTÁ PENADO POR LA LEY"</h6>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Sección para dispositivos móviles o pantallas pequeñas */}
                <div className="d-md-none">

                    {/* Aquí puedes poner el contenido específico para dispositivos móviles */}
                    <div className="row">
                        <div className="col-12 text-center mb-3">
                            <img src={LogoColor} alt="Logo" style={{ maxWidth: '70%' }} />
                        </div>
                        <div className="col-12 text-center mb-3">
                            <div className="card">
                                <div className="card-header">
                                    <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>Agencia Jukumaritravels</div>
                                    <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>RNT 138002 - NIT 71227531-3</div>
                                </div>
                                <div className="card-body">
                                    <h4>RECIBO: <span>Número de RECIBO</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border border-2 mb-3 p-3">
                        <div className="row mt-3">
                            <div className="col-12">
                                <h6><strong>LUGAR Y FECHA:</strong></h6>
                                <h6>Santa Rosa de Osos - (Ant), <span>{new Date(pagoAporte.pagoAporte.fechaAporte).toISOString().slice(0, 10)}</span></h6>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h6><strong>CLIENTE:</strong></h6>
                                {pagoAporte.clientes.map(cliente => (
                                    <div key={cliente._id}>
                                        <h6>{cliente.nombre1Cliente} {cliente.apellido1Cliente}</h6>
                                    </div>
                                ))}
                            </div>
                            <div className="col-12">
                                <h6><strong>DOCUMENTO:</strong></h6>
                                {pagoAporte.clientes.map(cliente => (
                                    <div key={cliente._id}>
                                        <h6>{cliente.documentoCliente}</h6>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h6><strong>EMAIL:</strong></h6>
                                {pagoAporte.clientes.map(cliente => (
                                    <div key={cliente._id}>
                                        <h6>{cliente.emailCliente}</h6>
                                    </div>
                                ))}
                            </div>
                            <div className="col-12">
                                <h6><strong>TELEFONO:</strong></h6>
                                {pagoAporte.clientes.map(cliente => (
                                    <div key={cliente._id}>
                                        <h6>{cliente.telefono1Cliente}</h6>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="border border-2 mb-2">
                        <div className="row mt-4 mb-3">
                            <div className="col-6">
                                <h6><strong>DESTINO:</strong></h6>
                                <h6>{pagoAporte.destinos.map(destino => (
                                    <div key={destino._id}>
                                        <p>{destino.nombreDestino}</p>
                                    </div>
                                ))}</h6>
                            </div>
                            <div className="col-6">
                                <h6><strong>PAQUETE:</strong></h6>
                                <h6>
                                    {pagoAporte.paquetesturisticos.map(paquete => (
                                        <div key={paquete._id}>
                                            <p>{paquete.nombrePaqueteTuristico}</p>
                                        </div>
                                    ))}
                                </h6>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="border border-2 mb-3 p-3">
                        <div className="text-center mt-3">
                            <h6><strong>CANTIDAD</strong></h6>
                            <div>1</div>
                        </div>
                        <div className="text-center mt-3">
                            <h6><strong>CONCEPTO</strong></h6>
                            <div><p>Pago aporte compra paquete turistico {nombresPaquetes} con destino a {nombresDestinos}.</p></div>
                        </div>
                        <div className="text-center mt-3">
                            <h6><strong>FORMA DE PAGO</strong></h6>
                            <div>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.mediodepagoAporte
                                    ? pagoAporte.pagoAporte.mediodepagoAporte.toUpperCase()
                                    : 'No Registra'}
                            </div>

                        </div>
                        <div className="text-center mt-3">
                            <h6><strong>TOTAL</strong></h6>
                            <div>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte !== null && pagoAporte.pagoAporte.valorAporte !== undefined
                                    ? Number(pagoAporte.pagoAporte.valorAporte).toLocaleString('es-CO', {
                                        style: 'currency', currency: 'COP', minimumFractionDigits: 2
                                    })
                                    : '0'}
                            </div>

                        </div>
                        <div className="text-center mt-3">
                            <strong>TOTAL</strong>
                            <div>
                                {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte !== null && pagoAporte.pagoAporte.valorAporte !== undefined
                                    ? Number(pagoAporte.pagoAporte.valorAporte).toLocaleString('es-CO', {
                                        style: 'currency', currency: 'COP', minimumFractionDigits: 2
                                    })
                                    : '0'}
                            </div>

                        </div>
                        <div className="text-center mt-3">
                            {pagoAporte && pagoAporte.pagoAporte && pagoAporte.pagoAporte.valorAporte
                                ? numeroEnLetras(parseFloat(pagoAporte.pagoAporte.valorAporte))
                                : 'No Registra'}
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="alert alert-info">
                                <h6>"LA ALTERACIÓN, FALSIFICACIÓN O COMERCIALIZACIÓN ILEGAL DE ESTE DOCUMENTO ESTÁ PENADO POR LA LEY"</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <PdfPreview pagoAporte={pagoAporte} nombresPaquetes={nombresPaquetes} nombresDestinos={nombresDestinos} />

        </div>
    );
};

export default PagoaportesPdf;
