import React from 'react';
import { Link } from 'react-router-dom';
import GenerateQRCode from '../page/GenerateQR';
import listaDestinosTuristicos from '../page/ListaDestinosTuristicos';

const Footer2 = () => {
    const primerosDestinos = listaDestinosTuristicos.slice(0, 5);
    return (
        <footer className="footer text-white py-4 py-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 item d-flex justify-content-center align-items-center mb-4 mb-md-0">
                        <div className="text-center">
                            <h3 className="footer-links-title"><strong>Servicios</strong></h3>
                            <hr />
                            <ul className="footer-links list-unstyled">
                                <li><Link className="link-light" to="/about">Reservaciones de vuelos</Link></li>
                                <li><Link className="link-light" to="/#team">Alojamiento</Link></li>
                                <li><Link className="link-light" to="/contact">Paquetes turísticos</Link></li>
                                <li><Link className="link-light" to="/terms-privacy">Transporte terrestre</Link></li>
                                <li><Link className="link-light" to="/terms-privacy">Visitas guiadas y excursiones</Link></li>
                                <li><Link className="link-light" to="/terms-privacy">Seguro de viaje</Link></li>
                                <li><Link className="link-light" to="/terms-privacy">Asesoramiento y planificación</Link></li>
                                <li><Link className="link-light" to="/terms-privacy">Gestión de documentos</Link></li>
                                <li><Link className="link-light" to="/terms-privacy">Atención al cliente durante el viaje</Link></li>
                                <li><Link className="link-light" to="/terms-privacy">Asesoramiento personalizado</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 item d-flex flex-column align-items-center mb-4 mb-md-0">
                        <div className="text-center">
                            <h3 className="footer-links-title"><strong>Destinos</strong></h3>
                            <hr />
                            <ul className="list-unstyled">
                                {primerosDestinos.map((destinos, index) => (
                                    <li key={index}>{destinos.destino}</li>
                                ))}
                                <br />
                                <Link to="/destinos" className="text-decoration-none text-white">Ver más destinos</Link>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 item d-flex flex-column align-items-center mb-4 mb-md-0">
                        <div className="text-center">
                            <h3><strong>Contactanos</strong></h3>
                            <hr />
                            <ul className="footer-links list-unstyled">
                                <li><Link className="link-light"><i className="fa-solid fa-phone me-2"></i>+57 304 3635185</Link></li>
                                <li><Link className="link-light"><i className="fa-solid fa-envelope me-2"></i>jukumaritravels@gmail.com</Link></li>
                            </ul>
                            <GenerateQRCode />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center pt-3">
                    <div className="container text-white py-4 py-lg-5">
                        <ul className="list-inline mb-2">
                            <li className="list-inline-item me-4"><Link className="link-light" to="#">Jukumaritravels hace realidad tus sueños de viajar</Link></li>
                        </ul>
                        <ul className="list-inline mb-2">
                            <li className="list-inline-item me-4">
                                <Link to="https://www.facebook.com/profile.php?id=100085310961891&mibextid=ZbWKwL" className="link-light"><i className="fa-brands fa-facebook fa-2xl"></i></Link>
                            </li>
                            <li className="list-inline-item me-4">
                                <Link to="https://instagram.com/jukumaritravels?igshid=MzNlNGNkZWQ4Mg==" className="link-light"><i className="fa-brands fa-instagram fa-2xl"></i></Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="https://www.tiktok.com/@jukumaritravels?_t=8dE4dmsl39a&_r=1" className="link-light"><i className="fa-brands fa-tiktok fa-2xl"></i></Link>
                            </li>
                        </ul>
                        <p className="copyright">Jukumaritravels © 2023</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer2;
