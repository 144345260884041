import React from 'react';
import { useUsuarios } from '../context/UsuariosContext';
import ListadeAyudas from './ListadeAyudas';

const Help = () => {
  const { usuarios } = useUsuarios();
  const { rolUsuario } = usuarios;

  // Filtrar las ayudas según el rol exacto del usuario
  const ayudasFiltradas = ListadeAyudas.filter(item => item.rolUsuario === rolUsuario);

  return (
    <div className="container mt-5">
      <h2 className="text-center">Ayudas para el {rolUsuario} </h2>
      <h5>{usuarios.nombreUsuario}</h5>
      <div className="row justify-content-center mt-4">
        {ayudasFiltradas.length > 0 ? (
          ayudasFiltradas.map((item, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 mb-4">
              <div className="card mx-auto d-flex flex-column" style={{ maxWidth: '25rem', height: '100%' }}>
                <h5 className="card-title text-center mt-2">{item.titulo}</h5>
                <div style={{ flex: 1 }}>
                  {item.localVideo.includes('youtube.com') ? (
                    <iframe
                      className="card-img-top"
                      src={item.localVideo}
                      title={item.titulo}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      allowFullScreen
                      style={{ width: '100%', height: '100%' }}
                    />
                  ) : (
                    <video controls width="100%" height="100%" className='border border-3 rounded-top'>
                      <source src={item.localVideo} type="video/mp4" />
                      Tu navegador no soporta el elemento de video.
                    </video>
                  )}
                </div>
                <div className="card-body">
                  <h5 className="card-title">Descripción: </h5>
                  <p className="card-text">{item.resena}</p>
                </div>
                <div className='mb-3'>
                  <p className="card-text"><strong>Rol Usuario: </strong>{item.rolUsuario}</p>
                  {item.pdf && (
                    <a className="btn btn-primary" href={item.pdf} target="_blank" rel="noopener noreferrer">
                      Previsualizar PDF
                    </a>
                  )}
                </div>
              </div>

            </div>
          ))
        ) : (
          <p className="text-center">No hay ayudas disponibles para el rol seleccionado.</p>
        )}
      </div>
    </div>

  );
};

export default Help;
