// locales/es.js
export const locales = {
    month: {
      long: 'Mes',
      short: 'Mes',
    },
    week: {
      long: 'Semana',
      short: 'Sem',
    },
    day: {
      long: 'Día',
      short: 'Día',
    },
    agenda: {
      long: 'Agenda',
      short: 'Agen',
    },
    today: 'Hoy',
    previous: 'Anterior',
    next: 'Siguiente',
    allDay: 'Todo el día',
    noEvents: 'No hay eventos',
  };
  