import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import imagen1 from '../image/mediosdepago/Medios-americanexpress.png';
import imagen2 from '../image/mediosdepago/Medios-baloto.png';
import imagen3 from '../image/mediosdepago/Medios-bancoccidente.png';
import imagen4 from '../image/mediosdepago/Medios-daviplata.png';
import imagen5 from '../image/mediosdepago/Medios-dinersclub.png';
import imagen6 from '../image/mediosdepago/Medios-efectiv.png';
import imagen7 from '../image/mediosdepago/Medios-mastercard.png';

const MediosDePagoCarousel = () => {
    const mediosDePago = [imagen1, imagen2, imagen3, imagen4, imagen5, imagen6, imagen7];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Ajustado para pantallas más grandes
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='container mt-5 mb-5'>
            <div className='text-center mb-4'>
                <span>
                    <small className="bor_header_left"></small>Nuestros medios de Pago
                    <small className="bor_header_right"></small>
                </span>
            </div>

            <Slider {...settings}>
                {mediosDePago.map((imagen, index) => (
                    <div key={index} className='d-flex justify-content-center'>
                        <img src={imagen} alt={`Medio de Pago ${index + 1}`} className='img-fluid' />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default MediosDePagoCarousel;
