import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";

const UsuariosContext = createContext();
const initialState = { login: false, token: '', nombreUsuario: '', id: '', rolUsuario: '' };

export const UsuariosProvider = (props) => {
    const [usuarios, setUsuarios] = useState(initialState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const initial = JSON.parse(localStorage.getItem('usuarios'))
        if (initial) {
            initial.login && setUsuarios(initial);
            // Imprimir el rol de usuario almacenado en localStorage
        } else {
            setUsuarios(initialState);
        }
    }, [])

    const loginUsuarios = async (usuarios, navigate) => {
        try {
            setLoading(true);
            const { data } = await axios.post("/api/usuarios/login", usuarios);
            setLoading(false);
            if (data.ok) {
                const usuariosLogin = {
                    login: true,
                    token: data.token,
                    nombreUsuario: data.nombreUsuario,
                    id: data._id,
                    rolUsuario: data.rolUsuario,
                };
                console.log('Rol de usuario:', data.rolUsuario); // Imprimir el rol de usuario obtenido del servidor
                localStorage.setItem('usuarios', JSON.stringify(usuariosLogin));
                setUsuarios(usuariosLogin);
                Swal.fire({
                    icon: 'success',
                    title: `${data.message}<br><span style="font-size: smaller;">${data.nombreUsuario}!</span>`,
                    showConfirmButton: false,
                    timer: 1500,
                });
                navigate('/aplicacion/');
            }
        } catch (error) {
            setLoading(false)
            if (!error.response.data.ok) {
                return Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            console.log('error usuariosLogin', error.message);
        }
    };
    
    const exit = () => {
        setUsuarios(initialState);
        localStorage.removeItem('usuarios');
        console.log('Sesión cerrada'); // Imprimir un mensaje al cerrar sesión
        return <Navigate to="/login" />;
    };
    const value = {
        usuarios,
        loginUsuarios,
        exit,
        loading,
    };
    return <UsuariosContext.Provider value={value} {...props} />;
};

export function useUsuarios() {
    const context = useContext(UsuariosContext);
    if (!context) {
        throw new Error('useUsuarios error');
    }
    return context;
};
