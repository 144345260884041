import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navibar from '../../page/Navibar'
import { useUsuarios } from '../context/UsuariosContext'
import '../view/Login.css'
import { Loading } from './Loading'

const Login = () => {
  const navigate = useNavigate()
  const [emailUsuario, setEmailUsuario] = useState("");
  const [passwordUsuario, setPasswordUsuario] = useState("");
  const { loading, loginUsuarios } = useUsuarios();


  const login = (e) => {
    e.preventDefault();
    const usuarios = { emailUsuario, passwordUsuario };
    loginUsuarios(usuarios, navigate)
  };

  return (
    <>
      <Navibar />
      {loading ? <Loading /> :
        <div >
          <div className="row justify-content-center imgfondo">
            <div className="col-md-6 col-xl-4 ">
              <br />
              <div className="card mt-5" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}>
                <div className="card-body text-center d-flex flex-column align-items-center mt">
                  <div className="bs-icon-xl bs-icon-primary bs-icon my-4"><i className="fa-solid fa-user fa-beat fa-2xl"></i></div>
                  <form onSubmit={login}>
                    <div className="mb-3">
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Usuario"
                        required
                        autoFocus
                        onChange={(e) => setEmailUsuario(e.target.value)}
                        value={emailUsuario}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Contraseña"
                        required
                        onChange={(e) => setPasswordUsuario(e.target.value)}
                        value={passwordUsuario}
                      />
                    </div>
                    <div className="mb-3">
                      <button
                        className="btn btn-primary d-block w-100"
                        type='submit'>
                        Ingresar
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Login