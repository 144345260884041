import { Document, Image, Link, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
//import { Linking } from 'react-native';


import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../Images/Footer.png';
import LogoColor from '../Images/LogoColor.png';
import { useUsuarios } from '../context/UsuariosContext';

const parseHTMLToPDF = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const parseNode = (node) => {
        switch (node.nodeName.toLowerCase()) {
            case 'ul': // Lista desordenada
                return (
                    <View key={node.textContent}>
                        {[...node.childNodes].map((childNode, index) => parseNode(childNode))}
                    </View>
                );
            case 'li': // Elemento de lista
                return (
                    <Text key={Math.random()} style={styles.listItem}>
                        • {parseChildNodes(node.childNodes)}
                    </Text>
                );
            case 'p': // Párrafo
                return (
                    <Text key={Math.random()} style={styles.paragraph}>
                        {parseChildNodes(node.childNodes)}
                    </Text>
                );
            case 'br': // Salto de línea
                return <View key={Math.random()} style={styles.lineBreak}><Text>{' '}</Text></View>; // Salto de línea usando un nuevo bloque Text vacío.
            case 'strong': // Texto en negrita
                return (
                    <Text key={Math.random()} style={styles.strong}>
                        {parseChildNodes(node.childNodes)}
                    </Text>
                );
            default: // Otros tipos de nodos
                return null;
        }
    };

    // Función para manejar nodos hijos
    const parseChildNodes = (childNodes) => {
        return [...childNodes].map((childNode, index) => {
            if (childNode.nodeName === '#text') {
                // Para texto plano
                return childNode.textContent;
            } else {
                // Para nodos de tipo 'elemento' (como br, strong, etc.)
                return parseNode(childNode);
            }
        });
    };


    // Procesamos todos los nodos del body del documento
    return [...doc.body.childNodes].map((node) => parseNode(node));
};



// Definir estilos
const styles = StyleSheet.create({
    page: {
        padding: 30,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    header: {
        position: 'fixed',
        top: 10,
        left: 1,
        right: 30,
        height: 80,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        flex: 1,
        marginTop: 30,
        marginBottom: 100,
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: 120,
        textAlign: 'center',
        //borderWidth: 2,
        //borderColor: '#ccc',
        marginTop: 20,
    },
    logo: {
        width: 170,
        height: 70,
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
    },
    subtitle: {
        fontSize: 10,
    },

    subtitle2: {
        fontSize: 8,
    },
    section: {
        marginBottom: 10, // Aplica un margen inferior de 5
    },

    sectionTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        borderBottomWidth: 0.7,
        borderBottomColor: 'black',
        paddingBottom: 5,
        marginBottom: 10,
        width: '100%',
        paddingVertical: 10,
    },
    table: {
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 0.4,
        borderBottomColor: '#ccc',
        paddingVertical: 4,
    },
    text: {
        fontSize: 10,
    },
    cell: {
        flex: 1,
        textAlign: 'center',
    },
    listItem: {
        fontSize: 10,
        marginBottom: 5,
    },

    paragraph: {
        marginBottom: 10,
        fontSize: 10,
        textAlign: 'justify',
    },
    strong: {
        fontWeight: 'bold',
    },
    lineBreak: {
        marginBottom: 10,
    }
});





// Componente MyDocument
const MyDocument = ({ reserva }) => {

    // Calcula el total de subtotales
    const totalPaquete = reserva.costosReserva
        .filter(costo => costo.cantidadcostoReserva && costo.cantidadcostoReserva > 0) // Filtrar costos con cantidad > 0
        .reduce((total, costo) => {
            const subtotal = Number(costo.subtotalcostoReserva) || 0; // Convertir a número o usar 0 si es NaN
            return total + subtotal;
        }, 0); // Sumar subtotales

    // Calcular el total de aportes
    const totalAportes = reserva.pagosOaportes
        .reduce((total, pago) => total + (Number(pago.valorAporte) || 0), 0);

    // Calcular el saldo pendiente
    const saldoPendiente = totalPaquete - reserva.totalDescuento - totalAportes;

    // Formateo de los valores a formato moneda COP
    const formatCurrency = (value) => {
        return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
    };



    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                {/* Encabezado */}
                <View style={styles.header} fixed>
                    <View style={[styles.titleContainer, { flex: 3 }]}>
                        <Image src={LogoColor} style={styles.logo} />
                    </View>
                    <View style={[styles.titleContainer, { flex: 5 }]}>
                        <Text style={styles.title}>Agencia Jukumaritravels</Text>
                        <Text style={styles.subtitle}>RNT 138002 - NIT 71227531-3</Text>
                        <Text style={styles.subtitle2}>{Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0
                            ? reserva.paquetesturisticos.map(paquete => paquete.nombrePaqueteTuristico).join(', ')
                            : 'No hay paquetes turísticos'}</Text>
                        <Text style={styles.subtitle2}>FECHA DE SALIDA: {new Date(reserva.fechaSalida).toISOString().slice(0, 10)}</Text>

                        <Text style={styles.subtitle2}>FECHA DE REGRESO: {new Date(reserva.fechaLlegada).toISOString().slice(0, 10)}</Text>
                    </View>
                    <View style={[styles.titleContainer, { flex: 3 }]}>
                        <Text style={styles.title}>{reserva.tipoDocumento}</Text>
                        <Text style={styles.subtitle}>
                            {reserva.tipoDocumento === 'COTIZACIÓN'
                                ? `COTI${formatNumeroRecibo(reserva.numeroCotizacion)}`
                                : `RES${formatNumeroRecibo(reserva.numeroReserva)}`}
                        </Text>
                    </View>
                </View>

                {/* Contenido principal */}
                <View style={styles.content}>
                    {/* Información del cliente */}
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Información Cliente</Text>
                    </View>

                    {/* Tabla de clientes */}
                    <View style={styles.section}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[styles.cell, { flex: 1 }]}><Text style={styles.text}>Documento</Text></View>
                                <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>Nombre</Text></View>
                                <View style={[styles.cell, { flex: 1 }]}><Text style={styles.text}>Teléfono</Text></View>
                                <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>Email</Text></View>
                            </View>
                            {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                reserva.clientes.map(cliente => (
                                    <View key={cliente._id} style={styles.tableRow}>
                                        <View style={[styles.cell, { flex: 1 }]}><Text style={styles.text}>{cliente.documentoCliente}</Text></View>
                                        <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>{Array.isArray(reserva.clientes) && reserva.clientes.length > 0
                                            ? reserva.clientes.map(cliente => {
                                                const nombres = [cliente.nombre1Cliente, cliente.nombre2Cliente].filter(Boolean).join(' ');
                                                const apellidos = [cliente.apellido1Cliente, cliente.apellido2Cliente].filter(Boolean).join(' ');
                                                return `${nombres} ${apellidos}`;
                                            }).filter(Boolean).join(', ')
                                            : 'No hay clientes'}</Text></View>
                                        <View style={[styles.cell, { flex: 1 }]}><Text style={styles.text}>{cliente.telefono1Cliente}</Text></View>
                                        <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>{cliente.emailCliente}</Text></View>
                                    </View>
                                ))
                            ) : (
                                <View style={styles.tableRow}>
                                    <View style={styles.cell}><Text style={styles.text}>No hay clientes</Text></View>
                                </View>
                            )}
                        </View>
                    </View>

                    {/* Información de la reserva */}
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Información Reserva</Text>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>Destino</Text></View>
                                <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>Fecha Reserva</Text></View>
                                <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>Fecha Salida</Text></View>
                                <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>Fecha Llegada</Text></View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>
                                    {Array.isArray(reserva.destinos) && reserva.destinos.length > 0
                                        ? reserva.destinos.map(destino => destino.nombreDestino).join(', ')
                                        : 'No hay destinos'}
                                </Text></View>
                                <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>{reserva.fechaReserva?.slice(0, 10)}</Text></View>
                                <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>{reserva.fechaSalida?.slice(0, 10)}</Text></View>
                                <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>{reserva.fechaLlegada?.slice(0, 10)}</Text></View>
                            </View>
                        </View>
                    </View>

                    {/* Costos del paquete */}
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Costos del Paquete</Text>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[styles.cell, { flex: 1 }]}><Text style={styles.text}>#</Text></View>
                                <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>Costo</Text></View>
                                <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>Cantidad</Text></View>
                                <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>Precio</Text></View>
                                <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>Subtotal</Text></View>
                            </View>
                            {reserva.costosReserva
                                .filter(costo => {
                                    const currentYear = new Date().getFullYear(); // Obtener el año actual
                                    return (
                                        costo.cantidadcostoReserva &&
                                        costo.cantidadcostoReserva !== 0 &&
                                        costo.anocostoReserva === currentYear // Filtrar por año actual
                                    );
                                })
                                .map((costo, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <View style={[styles.cell, { flex: 1 }]}><Text style={styles.text}>{index + 1}</Text></View>
                                        <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>{costo.nombredelcostoReserva || 'No Registra'}</Text></View>
                                        <View style={[styles.cell, { flex: 2 }]}><Text style={styles.text}>{costo.cantidadcostoReserva || 'No Registra'}</Text></View>
                                        <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>
                                            {costo.preciodelcostoReserva !== null && costo.preciodelcostoReserva !== undefined
                                                ? `${Number(costo.preciodelcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}`
                                                : '0'}
                                        </Text></View>
                                        <View style={[styles.cell, { flex: 3 }]}><Text style={styles.text}>
                                            {costo.subtotalcostoReserva !== null && costo.subtotalcostoReserva !== undefined
                                                ? `${Number(costo.subtotalcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}`
                                                : '0'}
                                        </Text></View>
                                    </View>
                                ))}

                        </View>
                    </View>


                    {reserva.tipoDocumento !== 'COTIZACIÓN' && (
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Historial de Pagos</Text>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>#</Text></View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Fecha</Text></View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Medio de Pago</Text></View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>Valor</Text></View>
                                </View>
                                {reserva.pagosOaportes.map((pago, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>{index + 1}</Text></View>
                                        <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>{pago.fechaAporte ? pago.fechaAporte.slice(0, 10) : 'No Registra'}</Text></View>
                                        <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>{pago.mediodepagoAporte ? pago.mediodepagoAporte.toUpperCase() : 'No Registra'}</Text></View>
                                        <View style={[{ flex: 3, textAlign: 'right' }]}><Text style={styles.text}>{(pago.valorAporte ?? 0).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</Text></View>
                                    </View>
                                ))}
                            </View>
                        </View>
                    )}


                    {reserva.tipoDocumento !== 'COTIZACIÓN' && (
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>..</Text>
                            <View style={styles.table}>
                                <View style={[styles.tableRow, { backgroundColor: 'whitesmoke' }]}>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>TOTAL PAQUETE</Text></View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>TOTAL DESCUENTOS</Text></View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>TOTAL APORTES</Text></View>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}><Text style={styles.text}>SALDO PENDIENTE</Text></View>
                                </View>

                                <View style={styles.tableRow}>
                                    <View style={[{ flex: 3, textAlign: 'center' }]}>
                                        <Text style={styles.text}>
                                            {totalPaquete > 0 ? formatCurrency(totalPaquete) : '0'}
                                        </Text>
                                    </View>

                                    <View style={[{ flex: 3, textAlign: 'center' }]}>
                                        <Text style={styles.text}>{formatCurrency(reserva.totalDescuento || 0)}</Text>
                                    </View>

                                    <View style={[{ flex: 3, textAlign: 'center' }]}>
                                        <Text style={styles.text}>{formatCurrency(totalAportes)}</Text>
                                    </View>

                                    <View style={[{ flex: 3, textAlign: 'center' }]}>
                                        <Text style={styles.text}>{formatCurrency(saldoPendiente)}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    )}

                    {reserva.tipoDocumento !== 'COTIZACIÓN' && (
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Información Pasajeros</Text>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>#</Text></View>
                                    <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>Documento</Text></View>
                                    <View style={[{ flex: 5, textAlign: 'center' }]}><Text style={styles.text}>Nombre</Text></View>
                                    <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>Acomodación</Text></View>
                                    <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>Grupo</Text></View>
                                </View>
                                {reserva.pasajeros.map((pasajero, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>{index + 1}</Text></View>
                                        <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>{pasajero.documentoPasajero || 'No Registra'}</Text></View>
                                        <View style={[{ flex: 5, textAlign: 'center' }]}><Text style={styles.text}>{pasajero.nombreCompletoPasajero || 'No Registra'}</Text></View>
                                        <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>{pasajero.acomodacionPasajero ? pasajero.acomodacionPasajero.toUpperCase() : 'No Registra'}</Text></View>
                                        <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>{pasajero.grupoAcomodacion ? pasajero.grupoAcomodacion : 'No Registra'}</Text></View>
                                    </View>
                                ))}
                            </View>
                        </View>
                    )}


                    {reserva.tipoDocumento !== 'COTIZACIÓN' && (
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Punto de Encuentro</Text>
                            <View style={styles.container}>
                                {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                    reserva.paquetesturisticos.map(paquete => {
                                        return (
                                            paquete.detallesPuntodeEncuentro && paquete.detallesPuntodeEncuentro.length > 0 ? (
                                                <View key={paquete._id}>
                                                    {/* Aquí se implementa la tabla de puntos de encuentro */}
                                                    <View style={styles.table}>
                                                        <View style={styles.tableRow}>
                                                            <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>#</Text></View>
                                                            <View style={[{ flex: 4, textAlign: 'center' }]}><Text style={styles.text}>Nombre</Text></View>
                                                            <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>Hora</Text></View>
                                                            <View style={[{ flex: 4, textAlign: 'center' }]}><Text style={styles.text}>Enlace</Text></View>
                                                        </View>
                                                        {paquete.detallesPuntodeEncuentro.map((detalle, index) => (
                                                            <View key={detalle._id} style={styles.tableRow}>
                                                                <View style={[{ flex: 1, textAlign: 'center' }]}><Text style={styles.text}>{index + 1}</Text></View>
                                                                <View style={[{ flex: 4, textAlign: 'center' }]}><Text style={styles.text}>{detalle.nombrePuntodeEncuentro || 'No Registra'}</Text></View>
                                                                <View style={[{ flex: 2, textAlign: 'center' }]}><Text style={styles.text}>{String(detalle.horaPuntodeEncuentro).padStart(4, '0').replace(/(\d{2})(\d{2})/, '$1:$2') || 'No Registra'}</Text></View>
                                                                <View style={{ flex: 4, textAlign: 'center' }}>
                                                                    {detalle.enlacePuntodeEncuentro ? (
                                                                        <Link src={encodeURI(detalle.enlacePuntodeEncuentro)}>
                                                                            <Text style={[styles.text, { color: 'blue', textDecorationLine: 'underline' }]}>
                                                                                Ver ubicación
                                                                            </Text>
                                                                        </Link>
                                                                    ) : (
                                                                        <Text>No Registra</Text>
                                                                    )}
                                                                </View>
                                                            </View>
                                                        ))}
                                                    </View>
                                                </View>
                                            ) : (
                                                <Text style={styles.text}>No hay puntos de encuentro disponibles</Text>
                                            )
                                        );
                                    })
                                ) : (
                                    <Text style={styles.text}>No hay paquetes turísticos</Text>
                                )}
                            </View>
                        </View>
                    )}


                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Itinerario</Text>
                        <View style={styles.container}>
                            {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                reserva.paquetesturisticos.map(paquete => (
                                    <View key={paquete._id}>
                                        <View style={styles.listContainer}>
                                            {parseHTMLToPDF(paquete.itinerario)}
                                        </View>
                                    </View>
                                ))
                            ) : (
                                <Text style={styles.text}>No hay paquetes turísticos</Text>
                            )}
                        </View>
                    </View>

                    {reserva.tipoDocumento !== 'COTIZACIÓN' && (
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Detalles del Paquete:</Text>
                            <View style={styles.container}>
                                {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                    reserva.paquetesturisticos.map(paquete => (
                                        <View key={paquete._id}>
                                            <View style={styles.table}>
                                                {paquete.detallesPaqueteTuristico && paquete.detallesPaqueteTuristico.length > 0 ? (
                                                    <>

                                                        {/* Elementos incluidos */}
                                                        <Text style={styles.sectionTitle}>Incluye:</Text>
                                                        {paquete.detallesPaqueteTuristico.filter(detalle => detalle.precioDetallesPaqueteTuristico === null).map((detalle, index) => (
                                                            <View key={index} style={styles.tableRow}>
                                                                <View style={{ flex: 4, textAlign: 'left' }}>
                                                                    <Text style={styles.text}>{detalle.nombredetallesPaqueteTuristico || 'No disponible'}</Text>
                                                                </View>
                                                                <View style={{ flex: 2, textAlign: 'right' }}>
                                                                    <Text style={styles.text}>
                                                                        {'Precio incluido'}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        ))}

                                                        {/* Elementos NO incluidos */}
                                                        <Text style={styles.sectionTitle}>No Incluye:</Text>
                                                        {paquete.detallesPaqueteTuristico.filter(detalle => detalle.precioDetallesPaqueteTuristico !== null).map((detalle, index) => (
                                                            <View key={index} style={styles.tableRow}>
                                                                <View style={{ flex: 4, textAlign: 'left' }}>
                                                                    <Text style={styles.text}>{detalle.nombredetallesPaqueteTuristico || 'No disponible'}</Text>
                                                                </View>
                                                                <View style={{ flex: 2, textAlign: 'right' }}>
                                                                    <Text style={styles.text}>
                                                                        {detalle.precioDetallesPaqueteTuristico
                                                                            ? detalle.precioDetallesPaqueteTuristico.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                                            : 'No disponible'}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        ))}

                                                    </>
                                                ) : (
                                                    <Text style={styles.text}>No hay detalles disponibles</Text>
                                                )}
                                            </View>
                                        </View>
                                    ))
                                ) : (
                                    <Text style={styles.text}>No hay paquetes turísticos</Text>
                                )}
                            </View>
                        </View>
                    )}





                    {reserva.tipoDocumento !== 'COTIZACIÓN' && (
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Recomendaciones:</Text>
                            <View style={styles.container}>
                                {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                    reserva.paquetesturisticos.map(paquete => (
                                        <View key={paquete._id}>
                                            <View>
                                                {parseHTMLToPDF(paquete.recomendaciones)}
                                            </View>
                                        </View>
                                    ))
                                ) : (
                                    <Text style={styles.text}>No hay paquetes turísticos</Text>
                                )}
                            </View>
                        </View>
                    )}



                    {reserva.tipoDocumento !== 'COTIZACIÓN' && (
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Observaciones:</Text>
                            <View style={styles.container}>
                                {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                    reserva.paquetesturisticos.map(paquete => (
                                        <View key={paquete._id}>
                                            <View>
                                                {parseHTMLToPDF(paquete.observaciones)}
                                            </View>
                                        </View>
                                    ))
                                ) : (
                                    <Text style={styles.text}>No hay paquetes turísticos</Text>
                                )}
                            </View>
                        </View>
                    )}

                </View>

                {/* Pie de página */}
                <View style={styles.footer} fixed>
                    <Image src={Footer} />
                </View>
            </Page>
        </Document>

    );
};

const PdfPreview = ({ reserva }) => (


    <PDFViewer style={{ width: '60%', height: '100vh' }}>
        <MyDocument reserva={reserva} />
    </PDFViewer>
);

function formatNumeroRecibo(numero) {
    return numero.toString().padStart(12, '0');
}


const ReservasPdf = () => {
    const { id } = useParams();
    const [reserva, setReserva] = useState(null);
    const [loading, setLoading] = useState(true);
    const { usuarios } = useUsuarios([]);

    useEffect(() => {
        const fetchData = async () => {
            // Mover 'options' dentro del useEffect
            const options = {
                headers: { authorization: "Bearer " + usuarios.token },
            };

            try {
                const response = await axios.get(`/api/reservas/${id}`, options);
                console.log('Response from API:', response);
                setReserva(response.data.message);
            } catch (error) {
                console.error('Error al obtener los datos de la reserva:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id, usuarios.token]);


    //---------------------------------------------------------------------------

    /* const generateAndDownloadPDF = async () => {
        // Lógica para generar el contenido del PDF utilizando react-pdf
        const { pdf } = await import('@react-pdf/renderer');

        // Crea el blob con el contenido del PDF
        const blob = await pdf(<MyDocument reserva={reserva} />).toBlob();

        // Obtiene la fecha y hora actual
        const currentDate = new Date();
        const year = currentDate.getFullYear().toString();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Se agrega 1 ya que los meses van de 0 a 11
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');

        // Crea el nombre del archivo con el formato deseado
        const fileName = `JKT-RES${year}${month}${day}-${hours}${minutes}${seconds}.pdf`;

        // Crea un enlace para descargar el PDF
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // Haz clic en el enlace para iniciar la descarga
        link.click();

    }; */

    const generateAndDownloadPDF = async () => {
        // Lógica para generar el contenido del PDF utilizando react-pdf
        const { pdf } = await import('@react-pdf/renderer');

        // Crea el blob con el contenido del PDF
        const blob = await pdf(<MyDocument reserva={reserva} />).toBlob();

        // Determina el nombre del archivo basado en el tipo de documento y número
        const fileName = reserva.tipoDocumento === 'COTIZACIÓN'
            ? `JKT-COTI${formatNumeroRecibo(reserva.numeroCotizacion)}.pdf`
            : `JKT-RES${formatNumeroRecibo(reserva.numeroReserva)}.pdf`;

        // Crea un enlace para descargar el PDF
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        // Haz clic en el enlace para iniciar la descarga
        link.click();
    };



    //----------------------------------------------------------------


    if (loading) {
        return <div>Cargando...</div>; // Muestra un indicador de carga mientras se obtienen los datos
    }

    if (!reserva) {
        return <div>No se encontró la reserva</div>; // Maneja el caso en el que la reserva no existe
    }

    // ----------------------------------------------------------------

    function formatNumeroRecibo(numero) {
        return numero.toString().padStart(12, '0');
    }

    // ---------SUMAS DE TOTALES -------------------------------------------------------

    // Calcula el total de subtotales
    const totalPaquete = reserva.costosReserva
        .filter(costo => costo.cantidadcostoReserva && costo.cantidadcostoReserva > 0)  // Filtrar costos con cantidad > 0
        .reduce((total, costo) => {
            const subtotal = Number(costo.subtotalcostoReserva) || 0;  // Convertir a número o usar 0 si es NaN
            return total + subtotal;
        }, 0);  // Sumar subtotales

    // Calcular el total de aportes
    const totalAportes = reserva.pagosOaportes
        .reduce((total, pago) => total + (Number(pago.valorAporte) || 0), 0);

    // Calcular el saldo pendiente
    const saldoPendiente = totalPaquete - reserva.totalDescuento - totalAportes;




    return (
        <div>
            <div className="container mt-5 justify-content-end mt-3 mb-3 d-none d-lg-block">
                <div className="col text-end">
                    <button className="btn btn-success btn-lg" onClick={generateAndDownloadPDF} >
                        Generar PDF
                        <i className="btn fa-solid fa-file-pdf fa-2xl text-white"></i>
                    </button>
                </div>
            </div>

            <div className="container mt-5 mb-3 d-lg-none">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 text-center">
                        <button className="btn btn-success btn-lg w-100" onClick={generateAndDownloadPDF} >
                            Generar PDF
                            <i className="btn fa-solid fa-file-pdf fa-2xl text-white"></i>
                        </button>
                    </div>
                </div>
            </div>

            {/* Seccion para dispositivos pc o pantallas grandes */}
            <div className="d-none d-lg-block">
                <div id="pdf-content" className='container'>
                    {/* Encabezado */}
                    <div id="pdf-content" className='container border border-3 mb-3'>
                        <div id="header" className="header">
                            <div className="container mt-3">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-4 text-center mb-3 mb-md-0">
                                        <img src={LogoColor} alt="Logo" style={{ maxWidth: '100%' }} />
                                    </div>
                                    <div className="col-12 col-md-4 text-center mb-3 mb-md-0">
                                        <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>Agencia Jukumaritravels</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>RNT 138002 - NIT 71227531-3</div>
                                    </div>
                                    <div className="col-12 col-md-4 text-center">
                                        <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>{reserva.tipoDocumento}</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                                            {reserva.tipoDocumento === 'COTIZACIÓN'
                                                ? `COTI${formatNumeroRecibo(reserva.numeroCotizacion)}`
                                                : `RES${formatNumeroRecibo(reserva.numeroReserva)}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Contenido principal */}
                    <div className="main-content container border border-3 mb-3">
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col">
                                    <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Cliente</h2>
                                </div>
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.nombre1Cliente}{cliente.apellido1Cliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                <div className="col"><strong>Documento:</strong></div>
                                <div className="col"><strong>Teléfono:</strong></div>
                                <div className="col"><strong>Correo Electrónico:</strong></div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.documentoCliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.telefono1Cliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.emailCliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col">
                                    <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Reserva</h2>
                                </div>
                            </div>
                        </div>
                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">
                                    {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                        <div>
                                            {reserva.paquetesturisticos.map(paquete => (
                                                <div key={paquete._id}>
                                                    {paquete.nombrePaqueteTuristico}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay paquetes turísticos'
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                <div className="col"><strong>Destino:</strong></div>
                                <div className="col"><strong>Fecha Reserva:</strong></div>
                                <div className="col"><strong>Fecha Salida:</strong></div>
                                <div className="col"><strong>Fecha Salida:</strong></div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {Array.isArray(reserva.destinos) && reserva.destinos.length > 0 ? (
                                        <div>
                                            {reserva.destinos.map(destino => (
                                                <div key={destino._id}>
                                                    {destino.nombreDestino}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay destinos'
                                    )}
                                </div>
                                <div className="col">{reserva.fechaReserva.slice(0, 10)}</div>
                                <div className="col">{reserva.fechaSalida.slice(0, 10)}</div>
                                <div className="col">{reserva.fechaLlegada.slice(0, 10)}</div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Costos del Paquete</h2>
                            </div>
                            <div className="container mt-2">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                    <div className="col"><strong>#</strong></div>
                                    <div className="col"><strong>Costo</strong></div>
                                    <div className="col"><strong>Cantidad</strong></div>
                                    <div className="col"><strong>Precio</strong></div>
                                    <div className="col"><strong>Subtotal</strong></div>
                                </div>
                                {reserva.costosReserva
                                    .filter(costo => costo.cantidadcostoReserva && costo.cantidadcostoReserva > 0)  // Asegurarse de que no sea null, undefined ni cero
                                    .map((costo, index) => (
                                        <div className="row" key={index}>
                                            <div className="col">{index + 1}</div>
                                            <div className="col">
                                                {costo.nombredelcostoReserva ? costo.nombredelcostoReserva : 'No Registra'}
                                            </div>
                                            <div className="col">
                                                {costo.cantidadcostoReserva}
                                            </div>
                                            <div className="col d-flex justify-content-end align-items-center">
                                                <div className="col text-end">
                                                    {costo.preciodelcostoReserva !== null && costo.preciodelcostoReserva !== undefined
                                                        ? Number(costo.preciodelcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                        : '0'}
                                                </div>
                                            </div>
                                            <div className="col d-flex justify-content-end align-items-center">
                                                <div className="col text-end">
                                                    {costo.subtotalcostoReserva !== null && costo.subtotalcostoReserva !== undefined
                                                        ? Number(costo.subtotalcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                        : '0'}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>

                        </div>


                        <div className="row mt-5">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Historial de Pagos</h2>
                            </div>
                            <div className="container mt-2 mb-5">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                    <div className="col-1"><strong>#</strong></div>
                                    <div className="col"><strong>Fecha</strong></div>
                                    <div className="col"><strong>Medio de Pago</strong></div>
                                    <div className="col"><strong>Valor</strong></div>
                                </div>
                                {reserva.pagosOaportes.map((pago, index) => (
                                    <div className="row" key={index}>
                                        <div className="col-1">{index + 1}</div>
                                        <div className="col"> {pago.fechaAporte ? pago.fechaAporte.slice(0, 10) : 'No Registra'}</div>
                                        <div className="col">
                                            {pago.mediodepagoAporte ? pago.mediodepagoAporte.toUpperCase() : 'No Registra'}
                                        </div>


                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className="col text-end">
                                                {pago.valorAporte !== null && pago.valorAporte !== undefined
                                                    ? pago.valorAporte.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="container mt-2 mb-5">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                    <div className="col-3"><strong>TOTAL PAQUETE</strong></div>
                                    <div className="col-3"><strong>TOTAL DESCUENTOS</strong></div>
                                    <div className="col-3"><strong>TOTAL APORTES</strong></div>
                                    <div className="col-3"><strong>SALDO PENDIENTE</strong></div>
                                </div>

                                <div className="row">
                                    <div className="col-3"> {totalPaquete.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</div>
                                    <div className="col-3"> {reserva.totalDescuento !== null && reserva.totalDescuento !== undefined
                                        ? reserva.totalDescuento.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                        : '0'}</div>
                                    <div className="col-3"> {totalAportes.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</div>
                                    <div className="col-3">{saldoPendiente.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className='container border border-3 mb-3'>
                        <div className="row mt-3">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Pasajeros</h2>
                            </div>
                        </div>
                        <div className="container mt-2 mb-3">
                            <div className="row" style={{ backgroundColor: 'whitesmoke', fontSize: '0.9rem' }}>
                                <div className="col-1"><strong>#</strong></div>
                                <div className="col-2"><strong>Documento</strong></div>
                                <div className="col-5"><strong>Nombre</strong></div>
                                <div className="col-3"><strong>Acomodación</strong></div>
                                <div className="col-1"><strong>Grupo</strong></div>
                            </div>
                            {reserva.pasajeros.map((pasajero, index) => (
                                <div className="row" key={index}>
                                    <div className="col-1">{index + 1}</div>
                                    <div className="col-2" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.documentoPasajero ? pasajero.documentoPasajero : 'No Registra'}
                                    </div>
                                    <div className="col-5" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.nombreCompletoPasajero ? pasajero.nombreCompletoPasajero : 'No Registra'}
                                    </div>

                                    <div className="col-3" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.acomodacionPasajero ? pasajero.acomodacionPasajero.toUpperCase() : 'No Registra'}
                                    </div>
                                    <div className="col-1" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.grupoAcomodacion ? pasajero.grupoAcomodacion : 'No Registra'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* 
                    <div className="container border border-3 mb-3" style={{ textAlign: 'left' }}>
                        <div className="row mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="container container-flex border-bottom mb-1 pb-2">
                                {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                    <div>
                                        {reserva.paquetesturisticos.map(paquete => (
                                            <div key={paquete._id}>
                                                <div>
                                                    <div className="col">
                                                        <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Itinerario:</h2>
                                                    </div>

                                                    <div dangerouslySetInnerHTML={{ __html: paquete.itinerario }} />
                                                </div>
                                                <div>
                                                    <div className="col">
                                                        <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Recomendaciones:</h2>
                                                    </div>

                                                    <div dangerouslySetInnerHTML={{ __html: paquete.recomendaciones }} />
                                                </div>
                                                <div>
                                                    <div className="col">
                                                        <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Observaciones:</h2>
                                                    </div>

                                                    <div dangerouslySetInnerHTML={{ __html: paquete.observaciones }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    'No hay paquetes turísticos'
                                )}
                            </span>
                        </div>
                    </div>

                    */}

                    {/* Pie de página */}
                    <div className='container border border-3'>
                        <div id="footer" className="container container-flex footer mt-1">
                            <img src={Footer} alt="" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </div>
                </div>
            </div>


            {/* Seccion para dispositivos moviles o pantallas pequelas */}
            <div className="d-lg-none">
                <div id="pdf-content" className='container'>
                    {/* Encabezado */}
                    <div id="pdf-content" className='container border border-3 mb-3'>
                        <div id="header" className="header">
                            <div className="container mt-3">
                                <div className="row align-items-center">
                                    <div className="col-12 text-center mb-3">
                                        <img src={LogoColor} alt="Logo" style={{ maxWidth: '80%' }} />
                                    </div>
                                    <div className="col-12 text-center mb-3">
                                        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>Agencia Jukumaritravels</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>RNT 138002 - NIT 71227531-3</div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>{reserva.tipoDocumento}</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                                            {reserva.tipoDocumento === 'COTIZACIÓN'
                                                ? `COTI${formatNumeroRecibo(reserva.numeroCotizacion)}`
                                                : `RES${formatNumeroRecibo(reserva.numeroReserva)}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Contenido principal */}
                    <div className="main-content container border border-3 mb-3">
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col">
                                    <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Cliente</h2>
                                </div>
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">
                                    <div className="d-flex align-items-center">
                                        <strong>Nombre:</strong>
                                        {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                            <div>
                                                {reserva.clientes.map(cliente => (
                                                    <div key={cliente._id}>
                                                        {cliente.nombre1Cliente}{cliente.apellido1Cliente}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ' No hay clientes'
                                        )}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong>Email:</strong>
                                        {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                            <div>
                                                {reserva.clientes.map(cliente => (
                                                    <div key={cliente._id}>
                                                        {cliente.emailCliente}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ' No hay clientes'
                                        )}
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">

                                </span>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                <div className="col"><strong>Documento:</strong></div>
                                <div className="col"><strong>Teléfono:</strong></div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.documentoCliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                                <div className="col">
                                    {Array.isArray(reserva.clientes) && reserva.clientes.length > 0 ? (
                                        <div>
                                            {reserva.clientes.map(cliente => (
                                                <div key={cliente._id}>
                                                    {cliente.telefono1Cliente}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        ' No hay clientes'
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col">
                                    <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Reserva</h2>
                                </div>
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'left' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="container container-flex border-bottom mb-1 pb-2">
                                    <div className="d-flex align-items-center">
                                        <strong>Destino Turístico:</strong>
                                        {Array.isArray(reserva.destinos) && reserva.destinos.length > 0 ? (
                                            <div className="ms-2">
                                                {reserva.destinos.map(destino => (
                                                    <div key={destino._id}>
                                                        {destino.nombreDestino}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ' No hay destinos'
                                        )}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong>Paquete Turístico:</strong>
                                        {Array.isArray(reserva.paquetesturisticos) && reserva.paquetesturisticos.length > 0 ? (
                                            <div className="ms-2">
                                                {reserva.paquetesturisticos.map(paquete => (
                                                    <span key={paquete._id}>
                                                        {paquete.nombrePaqueteTuristico}
                                                    </span>
                                                ))}
                                            </div>
                                        ) : (
                                            <span className="ms-2">No hay paquetes turísticos</span>
                                        )}
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                <div className="col"><strong>F.Reserva:</strong></div>
                                <div className="col"><strong>F.Salida:</strong></div>
                                <div className="col"><strong>F.Salida:</strong></div>
                            </div>
                            <div className="row">
                                <div className="col">{reserva.fechaReserva.slice(0, 10)}</div>
                                <div className="col">{reserva.fechaSalida.slice(0, 10)}</div>
                                <div className="col">{reserva.fechaLlegada.slice(0, 10)}</div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Costos del Paquete</h2>
                            </div>
                            <div className="container mt-2">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>
                                    <div className="col"><strong>Costo</strong></div>
                                    <div className="col"><strong>Cantidad</strong></div>
                                    <div className="col"><strong>Precio</strong></div>
                                    <div className="col"><strong>Subtotal</strong></div>
                                </div>
                                {reserva.costosReserva.map((costo, index) => (
                                    <div className="row" key={index}>
                                        <div className="col">
                                            {costo.nombredelcostoReserva ? costo.nombredelcostoReserva : 'No Registra'}
                                        </div>
                                        <div className="col">
                                            {costo.cantidadcostoReserva ? costo.cantidadcostoReserva : 'No Registra'}
                                        </div>

                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className='col text-end'>
                                                {costo && costo.preciodelcostoReserva !== null && costo.preciodelcostoReserva !== undefined
                                                    ? Number(costo.preciodelcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>

                                        </div>
                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className="col text-end">
                                                {costo && costo.subtotalcostoReserva !== null && costo.subtotalcostoReserva !== undefined
                                                    ? Number(costo.subtotalcostoReserva).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Historial de Pagos</h2>
                            </div>
                            <div className="container mt-2 mb-5">
                                <div className="row" style={{ backgroundColor: 'whitesmoke' }}>

                                    <div className="col"><strong>Fecha</strong></div>
                                    <div className="col"><strong>Medio P.</strong></div>
                                    <div className="col"><strong>Valor</strong></div>
                                </div>
                                {reserva.pagosOaportes.map((pago, index) => (
                                    <div className="row" key={index}>
                                        <div className="col">{pago.fechaAporte ? pago.fechaAporte.slice(0, 10) : 'No Registra'}</div>
                                        <div className="col">
                                            {pago.mediodepagoAporte ? pago.mediodepagoAporte.toUpperCase() : 'No Registra'}
                                        </div>

                                        <div className="col d-flex justify-content-end align-items-center">

                                            <div className='col text-end'>
                                                {pago && pago.valorAporte !== null && pago.valorAporte !== undefined
                                                    ? Number(pago.valorAporte).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
                                                    : '0'}
                                            </div>


                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='container border border-3 mb-3'>
                        <div className="row mt-3">
                            <div className="col">
                                <h2 className="mb-1 pb-2" style={{ textAlign: 'left', fontSize: '1.3rem', borderBottom: '2px solid black' }}>Información Pasajeros</h2>
                            </div>
                        </div>
                        <div className="container mt-2 mb-3">
                            <div className="row" style={{ backgroundColor: 'whitesmoke', fontSize: '0.9rem' }}>
                                <div className="col-7"><strong>Nombre</strong></div>
                                <div className="col-3"><strong>Acomodación</strong></div>
                            </div>
                            {reserva.pasajeros.map((pasajero, index) => (
                                <div className="row" key={index}>
                                    <div className="col-7" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.nombreCompletoPasajero ? pasajero.nombreCompletoPasajero : 'No Registra'}
                                    </div>

                                    <div className="col-3" style={{ fontSize: '0.9rem' }}>
                                        {pasajero.acomodacionPasajero ? pasajero.acomodacionPasajero.toUpperCase() : 'No Registra'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pie de página */}
                    <div className='container border border-3'>
                        <div id="footer" className="container container-flex footer mt-1">
                            <img src={Footer} alt="" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </div>
                </div>
            </div>

            <PdfPreview reserva={reserva} />


        </div>

    );
}

export default ReservasPdf;