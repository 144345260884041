import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../image/222222logosinfondo.png';
import Qr from './GenerateQRPage';
import './NotFound.css'; // Asegúrate de crear y personalizar este archivo CSS

const NotFound = () => {
    return (
        <div className="container-fluid not-found-background">
            {/* Contenido para pantallas grandes */}
            <div className="d-none d-lg-block">
                <div className="row justify-content-center align-items-center not-found-container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            {/* Aquí va el logo */}
                            <img src={Logo} alt="Logo" style={{ width: 400 }} />
                        </div>
                        <div className="col-auto">
                            {/* Aquí va el código QR */}
                            <Qr />
                           
                        </div>
                    </div>
                    <div className="col text-center">
                        <h1 style={{ fontFamily: 'Arial Black', fontSize: '200px' }}>404</h1>
                        <h6 style={{ fontFamily: 'Arial Black', fontSize: '60px' }}>Oops!</h6>
                        <p className="lost-text" style={{ fontFamily: 'Arial', fontSize: '30px' }}>Parece que te perdiste de playa... Si deseas te ayudamos a encontrarla.</p>
                        <h6 className="oops-text" style={{ fontFamily: 'Arial Black', fontSize: '40px' }}>Regresa y contáctanos</h6>
                        <Link to="/" className="home-link btn btn-primary mt-3">Clic aqui para regresar</Link>
                    </div>
                </div>
            </div>
            {/* Contenido para pantallas pequeñas */}
            <div className="d-block d-lg-none text-center">
                <img src={Logo} alt="Logo" className="img-fluid mt-5" />
                <div>
                    <h1 style={{ fontFamily: 'Arial Black', fontSize: '100px' }}>404</h1>
                    <h6 style={{ fontFamily: 'Arial Black', fontSize: '40px' }}>Oops!</h6>
                    <p className="lost-text" style={{ fontFamily: 'Arial', fontSize: '20px' }}>Parece que te perdiste de playa... Si deseas te ayudamos a encontrarla.</p>
                    <h6 className="oops-text" style={{ fontFamily: 'Arial Black', fontSize: '30px' }}>Regresa y contáctanos</h6>
                    <Link to="/" className="home-link btn btn-primary mt-3">Clic aqui para regresar</Link>
                </div>
                <img src={Qr} alt="QR Code" className="img-fluid mt-5" style={{ width: '200px' }} />
            </div>


        </div>
    );
};

export default NotFound;

