//----------------------------------------------------------------
import RolAsesorRegistroCliente from "../Images/help/rolasesor/RolAsesorModuloClientes.mp4";
import RolAsesorModuloClientepdf from "../Images/help/rolasesor/RolAsesorModuloClientes.pdf";
import RolAsesorModuloPaquetes from "../Images/help/rolasesor/RolAsesorModuloPaquetes.mp4";
import RolAsesorModuloPaquetespdf from "../Images/help/rolasesor/RolAsesorModuloPaquetes.pdf";
import RolAsesorModuloReservas from "../Images/help/rolasesor/RolAsesorModuloReservas.mp4";
import RolAsesorModuloReservaspdf from "../Images/help/rolasesor/RolAsesorModuloReservas.pdf";
//----------------------------------------------------------------
import RolAdminModuloClientepdf from "../Images/help/roladmin/RolAdminModuloClientes.pdf";
import RolAdminModuloDestinopdf from "../Images/help/roladmin/RolAdminModuloDestinos.pdf";
import RolAdminModuloPaquetespdf from "../Images/help/roladmin/RolAdminModuloPaquetes.pdf";
import RolAdminModuloProveedorespdf from "../Images/help/roladmin/RolAdminModuloProveedores.pdf";
import RolAdminModuloReservaspdf from "../Images/help/roladmin/RolAdminModuloReservas.pdf";
import RolAdminModuloUsuariospdf from "../Images/help/roladmin/RolAdminModuloUsuarios.pdf";

//------------------------------------------------------------------

import RolSuperAdminModuloClientespdf from "../Images/help/rolsuperadmin/RolSuperAdminModuloClientes.pdf";
import RolSuperAdminModuloDestinospdf from "../Images/help/rolsuperadmin/RolSuperAdminModuloDestinos.pdf";
import RolSuperAdminModuloPaquetespdf from "../Images/help/rolsuperadmin/RolSuperAdminModuloPaquetes.pdf";
import RolSuperAdminModuloProveedorespdf from "../Images/help/rolsuperadmin/RolSuperAdminModuloProveedores.pdf";
import RolSuperAdminModuloReservaspdf from "../Images/help/rolsuperadmin/RolSuperAdminModuloReservas.pdf";
import RolSuperAdminModuloUsuariospdf from "../Images/help/rolsuperadmin/RolSuperAdminModuloUsuarios.pdf";

//----------------------------------------------------------------

const ListadeAyudas = [
//---------------------------------------------------------------- rolUsuario === asesor ----------------------------------------------------------------
  {
    titulo: 'Modulo Cliente',
    localVideo: RolAsesorRegistroCliente,
    resena: 'El módulo de registro de clientes es una herramienta fundamental en nuestro sistema, diseñada para simplificar y optimizar el proceso de incorporación de nuevos clientes. Este módulo es intuitivo, seguro y eficiente, garantizando que los datos de los clientes se manejen de manera adecuada y cumpliendo con los estándares de privacidad y seguridad.',
    pdf: RolAsesorModuloClientepdf,
    rolUsuario:'Asesor',
  },
  {
    titulo: 'Modulo Paquetes',
    localVideo: RolAsesorModuloPaquetes,
    resena: 'El módulo de paquetes turísticos es una parte esencial de cualquier sistema de gestión en el ámbito del turismo, diseñado para facilitar la creación, gestión y comercialización de ofertas turísticas completas y atractivas. Este módulo proporciona a las agencias de viajes y operadores turísticos las herramientas necesarias para diseñar paquetes personalizados que satisfagan las necesidades y preferencias de sus clientes.',
    pdf: RolAsesorModuloPaquetespdf,
    rolUsuario:'Asesor',
  },
  {
    titulo: 'Modulo Reservas',
    localVideo: RolAsesorModuloReservas,
    resena: 'El módulo de reservas es el corazón del sistema de gestión turística, permitiendo administrar todas las reservas de servicios turísticos, desde alojamientos hasta actividades y transporte. Este módulo simplifica el proceso de reserva tanto para clientes individuales como para grupos. El módulo de reservas permite a las agencias de viajes y operadores turísticos ofrecer un servicio de alta calidad y maximizar la satisfacción del cliente.',
    pdf: RolAsesorModuloReservaspdf,
    rolUsuario:'Asesor',
  },
//---------------------------------------------------------------- rolUsuario === admin ---------------------------------------------------------------------
  {
    titulo: 'Registro de Reservas',
    localVideo: RolAsesorRegistroCliente,
    resena: 'El módulo de reservas es el corazón del sistema de gestión turística, permitiendo administrar todas las reservas de servicios turísticos, desde alojamientos hasta actividades y transporte. Este módulo simplifica el proceso de reserva tanto para clientes individuales como para grupos. El módulo de reservas permite a las agencias de viajes y operadores turísticos ofrecer un servicio de alta calidad y maximizar la satisfacción del cliente.',
    pdf: RolAdminModuloReservaspdf,
    rolUsuario:'Admin',
  },
  {
    titulo: 'Registro de Paquetes',
    localVideo: RolAsesorRegistroCliente,
    resena: 'El módulo de paquetes turísticos es una parte esencial de cualquier sistema de gestión en el ámbito del turismo, diseñado para facilitar la creación, gestión y comercialización de ofertas turísticas completas y atractivas. Este módulo proporciona a las agencias de viajes y operadores turísticos las herramientas necesarias para diseñar paquetes personalizados que satisfagan las necesidades y preferencias de sus clientes.',
    pdf: RolAdminModuloPaquetespdf,
    rolUsuario:'Admin',
  },
  {
    titulo: 'Registro de Destinos',
    localVideo: RolAsesorRegistroCliente,
    resena: 'El módulo de destinos es una pieza clave dentro del sistema de gestión turística, diseñado para administrar y optimizar la información y actividades relacionadas con diversos destinos turísticos. Este módulo permite a las agencias de viajes y operadores turísticos gestionar eficientemente los detalles de los destinos, mejorando la experiencia del cliente y facilitando la operación del negocio.',
    pdf: RolAdminModuloDestinopdf,
    rolUsuario:'Admin',
  },
  {
    titulo: 'Registro de Clientes',
    localVideo: RolAsesorRegistroCliente,
    resena: 'El módulo de registro de clientes es una herramienta fundamental en nuestro sistema, diseñada para simplificar y optimizar el proceso de incorporación de nuevos clientes. Este módulo es intuitivo, seguro y eficiente, garantizando que los datos de los clientes se manejen de manera adecuada y cumpliendo con los estándares de privacidad y seguridad.',
    pdf: RolAdminModuloClientepdf,
    rolUsuario:'Admin',
  },
  {
    titulo: 'Registro de Proveedores',
    localVideo: RolAsesorRegistroCliente,
    resena: 'El módulo de proveedores es una herramienta esencial dentro del sistema de gestión empresarial, diseñada para optimizar la relación y gestión con los proveedores de bienes y servicios. Este módulo facilita la administración eficiente de todos los aspectos relacionados con los proveedores, desde el registro inicial hasta la evaluación y seguimiento de su desempeño.',
    pdf: RolAdminModuloProveedorespdf,
    rolUsuario:'Admin',
  },
  {
    titulo: 'Registro de Usuarios',
    localVideo: RolAsesorRegistroCliente,
    resena: 'El registro de usuarios es una funcionalidad esencial en muchas aplicaciones web, permitiendo a los usuarios crear cuentas y acceder a funciones personalizadas.',
    pdf: RolAdminModuloUsuariospdf,
    rolUsuario:'Admin',
  },
//---------------------------------------------------------------- rolUsuario === SuperAdmin ----------------------------------------------------------------------
{
  titulo: 'Registro de Reservas',
  localVideo: RolAsesorRegistroCliente,
  resena: 'El módulo de reservas es el corazón del sistema de gestión turística, permitiendo administrar todas las reservas de servicios turísticos, desde alojamientos hasta actividades y transporte. Este módulo simplifica el proceso de reserva tanto para clientes individuales como para grupos. El módulo de reservas permite a las agencias de viajes y operadores turísticos ofrecer un servicio de alta calidad y maximizar la satisfacción del cliente.',
  pdf: RolSuperAdminModuloReservaspdf,
  rolUsuario:'SuperAdmin',
},
{
  titulo: 'Registro de Paquetes',
  localVideo: RolAsesorRegistroCliente,
  resena: 'El módulo de paquetes turísticos es una parte esencial de cualquier sistema de gestión en el ámbito del turismo, diseñado para facilitar la creación, gestión y comercialización de ofertas turísticas completas y atractivas. Este módulo proporciona a las agencias de viajes y operadores turísticos las herramientas necesarias para diseñar paquetes personalizados que satisfagan las necesidades y preferencias de sus clientes.',
  pdf: RolSuperAdminModuloPaquetespdf,
  rolUsuario:'SuperAdmin',
},
{
  titulo: 'Registro de Destinos',
  localVideo: RolAsesorRegistroCliente,
  resena: 'El módulo de destinos es una pieza clave dentro del sistema de gestión turística, diseñado para administrar y optimizar la información y actividades relacionadas con diversos destinos turísticos. Este módulo permite a las agencias de viajes y operadores turísticos gestionar eficientemente los detalles de los destinos, mejorando la experiencia del cliente y facilitando la operación del negocio.',
  pdf: RolSuperAdminModuloDestinospdf,
  rolUsuario:'SuperAdmin',
},
{
  titulo: 'Registro de Clientes',
  localVideo: RolAsesorRegistroCliente,
  resena: 'El módulo de registro de clientes es una herramienta fundamental en nuestro sistema, diseñada para simplificar y optimizar el proceso de incorporación de nuevos clientes. Este módulo es intuitivo, seguro y eficiente, garantizando que los datos de los clientes se manejen de manera adecuada y cumpliendo con los estándares de privacidad y seguridad.',
  pdf: RolSuperAdminModuloClientespdf,
  rolUsuario:'SuperAdmin',
},
{
  titulo: 'Registro de Proveedores',
  localVideo: RolAsesorRegistroCliente,
  resena: 'El módulo de proveedores es una herramienta esencial dentro del sistema de gestión empresarial, diseñada para optimizar la relación y gestión con los proveedores de bienes y servicios. Este módulo facilita la administración eficiente de todos los aspectos relacionados con los proveedores, desde el registro inicial hasta la evaluación y seguimiento de su desempeño.',
  pdf: RolSuperAdminModuloProveedorespdf,
  rolUsuario:'SuperAdmin',
},
{
  titulo: 'Registro de Usuarios',
  localVideo: RolAsesorRegistroCliente,
  resena: 'El registro de usuarios es una funcionalidad esencial en muchas aplicaciones web, permitiendo a los usuarios crear cuentas y acceder a funciones personalizadas.',
  pdf: RolSuperAdminModuloUsuariospdf,
  rolUsuario:'SuperAdmin',
},




  
];

export default ListadeAyudas;